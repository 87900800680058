import React from 'react';
import Action from '../redux/Action';
import { useDispatch, useSelector } from 'react-redux';

const PromoChargeInput = () => {
    const dispatch = useDispatch();
    const formValue = useSelector(state => state.reducer.formValue);

    return (
        <div className="flex flex-row items-center gap-x-5">
            <div className="flex items-center">
                <input
                    checked={formValue.promoCharge === "ADMIN"}
                    onChange={(e) => {
                        const name = e.target.name;
                        const value = e.target.value;
                        Action.handleFormChange(dispatch, { name, value, formValue });
                    }}
                    id="ADMIN"
                    type="radio"
                    value="ADMIN"
                    name="promoCharge"
                    className="w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary" />
                <label
                    htmlFor="ADMIN"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Admin
                </label>
            </div>
            <div className="flex items-center">
                <input
                    checked={formValue.promoCharge === "MERCHANT"}
                    onChange={(e) => {
                        const name = e.target.name;
                        const value = e.target.value;
                        Action.handleFormChange(dispatch, { name, value, formValue });
                    }}
                    id="MERCHANT"
                    type="radio"
                    value="MERCHANT"
                    name="promoCharge"
                    className="w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary" />
                <label
                    htmlFor="MERCHANT"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Merchant
                </label>
            </div>
            <div className="flex items-center">
                <input
                    checked={formValue.promoCharge === "ADMIN_AND_MERCHANT"}
                    onChange={(e) => {
                        const name = e.target.name;
                        const value = e.target.value;
                        Action.handleFormChange(dispatch, { name, value, formValue });
                    }}
                    id="ADMIN_AND_MERCHANT"
                    type="radio"
                    value="ADMIN_AND_MERCHANT"
                    name="promoCharge"
                    className="w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary" />
                <label
                    htmlFor="ADMIN_AND_MERCHANT"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Admin & Merchant
                </label>
            </div>
        </div>
    );
};

export default PromoChargeInput;