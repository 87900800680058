// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switchSmall2 {
  display: inline-block;
}
.switchSmall2 input {
  display: none;
}
.switchSmall2 small {
  display: inline-block;
  width: 23px;
  height: 15px;
  background: #cad4d1;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
}
.switchSmall2 small:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  top: 2px;
  left: 1px;
  transition: 0.3s;
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.25);
}
.switchSmall2 input:checked ~ small {
  background: #5d7d73;
  transition: 0.3s;
}
.switchSmall2 input:checked ~ small:before {
  transform: translate(12px, 0px);
  transition: 0.3s;
  box-shadow: 3px 0 3px rgba(0, 0, 0, 0.25);
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth/login/Login.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,aAAa;AACf;AACA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gBAAgB;EAChB,0CAA0C;AAC5C;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,yCAAyC;AAC3C","sourcesContent":[".switchSmall2 {\n  display: inline-block;\n}\n.switchSmall2 input {\n  display: none;\n}\n.switchSmall2 small {\n  display: inline-block;\n  width: 23px;\n  height: 15px;\n  background: #cad4d1;\n  border-radius: 30px;\n  position: relative;\n  cursor: pointer;\n}\n.switchSmall2 small:before {\n  content: \"\";\n  position: absolute;\n  width: 10px;\n  height: 10px;\n  background: #fff;\n  border-radius: 50%;\n  top: 2px;\n  left: 1px;\n  transition: 0.3s;\n  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.25);\n}\n.switchSmall2 input:checked ~ small {\n  background: #5d7d73;\n  transition: 0.3s;\n}\n.switchSmall2 input:checked ~ small:before {\n  transform: translate(12px, 0px);\n  transition: 0.3s;\n  box-shadow: 3px 0 3px rgba(0, 0, 0, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
