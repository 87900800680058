import React from "react";
import { useTranslation } from "react-multi-lang";
import Action from "../redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { setShowDetail } from "../redux/Reducer";
import DateRangePicker from "../../../../../components/DateRangePicker/DateRangePicker";

const HeaderTable = ({
    datePickerOnOpen,
    datePickerOnChange,
    datePickerOnClose
}) => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const search = useSelector((state) => state.reducer.search);
    const statusFilter = useSelector((state) => state.reducer.statusFilter);
    const postPerPage = useSelector((state) => state.reducer.postPerPage);
    const fieldName = useSelector((state) => state.reducer.fieldName);
    const orderBy = useSelector((state) => state.reducer.orderBy);
    const fromDate = useSelector((state) => state.reducer.fromDate);
    const toDate = useSelector((state) => state.reducer.toDate);

    let filterTimeout;
    return (
        <div className="grid sm:grid-cols-2 md:grid-cols-5 xl:grid-cols-12 gap-2  items-center">
            <div className="lg:col-span-2 font-bold opacity-60 lg:text-md md:text-sm">
                {t("management.transaction_list").toUpperCase()}
            </div>
            <div className="lg:col-span-3 md:col-span-4">
                <label className="relative block">
                    <span className="absolute inset-y-0 left-1.5 flex items-center pl-2">
                        <img
                            className="h-3.5 w-5 fill-slate-300"
                            src={process.env.REACT_APP_ASSETS_IMAGE + "username-icon.svg"}
                            alt="username-icon"
                        />
                    </span>
                    <input
                        type="text"
                        placeholder={t("management.search_transaction")}
                        className="pl-10 pr-3 w-full block sm:text-sm shadow-sm border border-gray-primary rounded-xl bg-white  placeholder:text-slate-40 "
                        onChange={(e) => {
                            clearTimeout(filterTimeout);
                            filterTimeout = setTimeout(() => {
                                Action.getList(dispatch, {
                                    currentPage: 0,
                                    search: e.target.value,
                                    statusFilter,
                                    postPerPage,
                                    fieldName,
                                    orderBy,
                                    toDate,
                                    fromDate
                                });
                                dispatch(setShowDetail(false));
                            }, 200);
                        }}
                    />
                </label>
            </div>
            <div className="lg:col-span-3 md:col-span-4 ">
                <DateRangePicker
                    startDateValue={fromDate}
                    endDateValue={toDate}
                    onOpen={() => {
                        datePickerOnOpen();
                    }}
                    onChange={(dateObject) => {
                        datePickerOnChange(dateObject);
                    }}
                    onClose={() => {
                        datePickerOnClose();
                    }}
                    format="DD MMM YYYY"
                    placeholder={t("buyback.date_range_placeholder")}
                    inputClass="w-full block sm:text-sm shadow-sm border border-gray-primary rounded-xl bg-white placeholder:text-slate-40 cursor-pointer"
                    containerClassName="w-full"
                />
            </div>
            <div className="lg:col-span-2 md:col-span-2">
                <select
                    className="w-full sm:text-sm shadow-sm border border-gray-primary rounded-xl"
                    name="status"
                    id="status"
                    onChange={(e) => {
                        Action.getList(dispatch, {
                            currentPage: 0,
                            search,
                            statusFilter: e.target.value,
                            postPerPage,
                            fieldName,
                            orderBy,
                            toDate,
                            fromDate
                        });
                        dispatch(setShowDetail(false));
                    }}
                >
                    <option value="">{t("management.all_status")}</option>
                    <option value="-2">{t("management.complained")}</option>
                    <option value="-1">{t("management.canceled")}</option>
                    {/* <option value="0">{t("management.pending")}</option> */}
                    <option value="1">{t("management.processed")}</option>
                    <option value="2">{t("management.on_shipment")}</option>
                    <option value="3">{t("management.received")}</option>
                    <option value="4">{t("management.completed")}</option>
                    <option value="11">{t("management.READY_TO_SELF_PICKUP")}</option>
                    {/* <option value="8">{t("management.not_withdraw")}</option> */}
                    {/* <option value="9">{t("management.pending_withdraw")}</option> */}
                    {/* <option value="10">{t("management.withdraw")}</option> */}
                </select>
            </div>
            <div className="lg:col-span-2 md:col-span-3">
                <label htmlFor="postperview" className="pr-2 text-sm font-medium">
                    {t("management.view")}
                </label>
                <select
                    className="sm:text-sm shadow-sm border border-gray-primary rounded-xl"
                    name="postperview"
                    id="postperview"
                    onChange={(e) => {
                        Action.getList(dispatch, {
                            currentPage: 0,
                            search,
                            statusFilter,
                            postPerPage: parseInt(e.target.value),
                            fieldName,
                            orderBy,
                            toDate,
                            fromDate
                        });
                        dispatch(setShowDetail(false));
                    }}
                >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                </select>
            </div>
        </div>
    );
};

export default HeaderTable;