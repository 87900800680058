import React, { useEffect } from "react";
import StoreHelper from "../../../services/store-helper";
import { useTranslation } from "react-multi-lang";
import ButtonGold from "../../../components/ButtonGold/ButtonGold";
import { Provider, useDispatch, useSelector } from "react-redux";
import Action from "./redux/Action";
import reducerSlice from "./redux/Reducer";
import { useNavigate } from "react-router-dom";
import RouteName from "../../../services/routename";
import Cookies from "js-cookie";

const ScanQR = () => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();

  const MainComponent = () => {
    const navigate = useNavigate();
    const t = useTranslation();
    const dispatch = useDispatch();

    const qrCode = useSelector((state) => state.reducer.qrCode);

    const handleButtonClick = () => {
      navigate(RouteName.authenticationcode);
    };

    useEffect(() => {
      Action.generateQR(dispatch);
    
    }, [])

    
    return (
      <>
        <div className="section py-10 px-6 lg:mx-20 md:mx-28">
          <form className="font-medium">
            <div className="pl-2 pt-4">
              <h2 className="text-green-secondary font-bold">
                {t("login.scanqrcode")}
              </h2>
              <h3>{t("login.subtitlescanqrcode")}</h3>
            </div>

            <div className="mt-10 bg-white rounded-xl p-1 w-auto mx-auto h-64 max-w-[250px]">
              <img
                src={`data:image/png;base64, ${qrCode}`}
                alt="QR Code"
                className="p-[6px] w-full h-full"
              />
            </div>

            <div className="pt-12 text-right">
              <ButtonGold
                title={t("login.scanqrnext").toUpperCase()}
                onClick={handleButtonClick}
              />
            </div>
          </form>
        </div>
      </>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default ScanQR;
