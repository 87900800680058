import React, { useEffect } from 'react';
import { useTranslation } from 'react-multi-lang';
import Cookies from "js-cookie";
import EncryptDecrypt from '../../services/encrypt-decrypt';

const FreezeMode = () => {
    const t = useTranslation();
    let buffer = '';

    useEffect(() => {
        const handleKeyDown = (event) => {
            buffer += event.key;
            if (buffer.length > 10) {
                buffer = buffer.slice(-10); // Hanya menyimpan 10 karakter terakhir
            }
            if (buffer === 'SBYTNMMS11') {
                window.location.href = '/';
                Cookies.set("status_maintenance", EncryptDecrypt.enc("PASSED"));
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className='w-full h-screen flex items-center justify-center bg-[#f7f7f7]'>
            <div className='text-center flex flex-col justify-center items-center'>
                <img
                    className="object-contain w-full max-w-[450px] mx-auto d-block"
                    src={process.env.REACT_APP_ASSETS_IMAGE + "freezemode-bg.jpeg"}
                    alt=""
                />
                <h1 className='mb-2 mt-4 font-bold text-3xl'>
                    {t("freezemode.title")}
                </h1>
                <p className='font-medium text-md w-1/2'>
                    {t("freezemode.desc")}
                </p>
            </div>
        </div>
    );
};

export default FreezeMode;
