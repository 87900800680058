import React from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import ButtonGreen from "../../../../../components/ButtonGreen/ButtonGreen";
import ButtonWhite from "../../../../../components/ButtonWhite/ButtonWhite";
import ConfirmationModal from "../../../../../components/ConfirmationModal/ConfirmationModal";
import Action from "../redux/Action";
import DateUtility from "../../../../../utils/date-utility";
import UseAuth from "../../../../auth/Auth";
import Enum from "./Enum";
import { setErrorSusComplaint, setIdentifier, setModalSusAction, setModalSusCustomer, setModalSusMerchant } from "../redux/Reducer";
import ConfirmationSuspendModal from "./ConfirmationSuspendModal";

const Detail = () => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const detailCustomer = useSelector(state => state.reducer.detailCustomer);
  const modalReqResolved = useSelector(state => state.reducer.modalReqResolved);
  const modalResolved = useSelector(state => state.reducer.modalResolved);
  const modalSendEmail = useSelector(state => state.reducer.changeModalSendEmail);
  const modalSusMerchant = useSelector(state => state.reducer.modalSusMerchant);
  const modalSusCustomer = useSelector(state => state.reducer.modalSusCustomer);
  const modalSusAction = useSelector(state => state.reducer.modalSusAction);
  const identifier = useSelector(state => state.reducer.identifier);

  const recipient = detailCustomer.email;
  const subject =
    "[" +
    detailCustomer.feedbackId +
    "]" +
    " - " +
    detailCustomer.category +
    " - " +
    detailCustomer.topic;

  function handleSendEmail() {
    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}`;
  }

  return (
    <div className="font-medium text-sm">
      <div className="flex flex-row items-center pb-4">
        <h4 className="flex-1 opacity-60 font-bold">
          {t("helpcenter.details").toUpperCase()}
        </h4>
      </div>

      <div className="h-72 overflow-y-auto">
        <div className="flex flex-row items-right pb-2">
          <div className="flex-1">
            <p className="text-green-quaternary">
              {t("helpcenter.feedback_id")}
            </p>
            <p className="font-bold text-md">{detailCustomer.feedbackId}</p>
          </div>
          <div className="text-right">
            <div className="min-w-fit py-1 px-4 text-center text-green-primary font-bold rounded-xl border-2 border-green-secondary border-opacity-40">
              {Enum(t, detailCustomer.status)}
            </div>
          </div>
        </div>

        <div className="pb-2">
          <p className="text-green-quaternary">{t("helpcenter.invoice_id")}</p>
          <p className="font-bold text-md">{detailCustomer.noInvoice}</p>
        </div>

        <div className="pb-2">
          <p className="text-green-quaternary">{t("helpcenter.category")}</p>
          <p className="font-bold text-md">{detailCustomer.category}</p>
        </div>

        {/* <div className="pb-2">
          <p className="text-green-quaternary">{t("helpcenter.topic")}</p>
          <p className="font-bold text-md">{detailCustomer.topic}</p>
        </div> */}

        <div className="pb-2">
          <p className="text-green-quaternary">
            {t("helpcenter.customer_name")}
          </p>
          <p className="font-bold text-md">{detailCustomer.name}</p>
        </div>

        <div className="pb-2">
          <p className="text-green-quaternary">{t("helpcenter.email")}</p>
          <p className="font-bold text-md">{detailCustomer.email}</p>
        </div>

        <div className="pb-2">
          <p className="text-green-quaternary">{t("helpcenter.phone_no")}</p>
          <p className="font-bold text-md">{detailCustomer.phoneNumber}</p>
        </div>

        <div className="pb-2">
          <p className="text-green-quaternary">{t("helpcenter.merchant_name")}</p>
          <p className="font-bold text-md">{detailCustomer.merchantName !== "" ? detailCustomer.merchantName : "-"}</p>
        </div>

        <div className="pb-2">
          <p className="text-green-quaternary">{t("helpcenter.issued_date")}</p>
          <p className="font-bold text-md">
            {DateUtility.formatDate(detailCustomer.issuedDate, "full")}
          </p>
        </div>

        <div className="pb-2">
          <p className="text-green-quaternary">{t("helpcenter.products")}</p>
          {detailCustomer.products ? detailCustomer.products.map((item, i) => {
            return <React.Fragment key={i}>
              <p className="font-bold text-sm">
                {item.productName}
              </p>
              <p className="text-green-secondary text-sm">
                {item.productQtyPrice}
              </p>
            </React.Fragment>;
          }) : "-"}
        </div>

        {detailCustomer.remarks !== "" ? (
          <>
            <div className="pb-2">
              <p className="text-green-quaternary">{t("helpcenter.feedback")}</p>
              <p className="font-bold text-md">{detailCustomer.remarks}</p>
            </div>
            <hr />
          </>
        ) : ""}

        {detailCustomer.resolvedBy !== "" ? (
          <div className="pb-2 pt-4">
            <p className="text-green-quaternary">
              {t("helpcenter.resolved_by")}
            </p>
            <p className="font-bold text-md">{detailCustomer.resolvedBy}</p>
          </div>
        ) : ""}

        {detailCustomer.remarksAdmin !== "" ? (
          <div className="pb-2">
            <p className="text-green-quaternary">{t("helpcenter.remarks")}</p>
            <p className="font-bold text-md">{detailCustomer.remarksAdmin}</p>
          </div>

        ) : ""}
      </div>

      <div className="flex flex-row-reverse gap-x-2">
        {detailCustomer.status === 1 && UseAuth.checkFunction("reqresolve-customer-helpcenter-button", "button") === true ? (
          <ButtonGreen
            title={t("helpcenter.req_resolve")}
            onClick={() => {
              Action.changeModalReqResolved(dispatch, { showModal: true });
            }}
          />
        ) : ""}

        {detailCustomer.status === 2 && UseAuth.checkFunction("setasresolved-customer-helpcenter-button", "button") === true ? (
          <ButtonGreen
            title={t("helpcenter.resolved")}
            onClick={() => {
              Action.changeModalResolved(dispatch, { showModal: true });
            }}
          />
        ) : ""}

        {detailCustomer.status === 1 && UseAuth.checkFunction("directemail-customer-helpcenter", "button") === true ? (
          <ButtonWhite
            title={t("helpcenter.direct_email")}
            onClick={handleSendEmail}
          />
        ) : ""}

        {detailCustomer.buttonSuspend &&
          detailCustomer.status === 5 &&
          UseAuth.checkFunction("accept-suspend-helpcenter-button", "button") ? (
          <ButtonGreen
            title={t("general.accepted")}
            onClick={() => {
              dispatch(setIdentifier("accept-suspend-helpcenter-button"));
              dispatch(setModalSusAction(true));
            }}
          />
        ) : ""}

        {detailCustomer.buttonSuspend &&
          detailCustomer.status === 5 &&
          UseAuth.checkFunction("decline-suspend-helpcenter-button", "button") ? (
          <ButtonWhite
            title={t("general.declined")}
            onClick={() => {
              dispatch(setIdentifier("decline-suspend-helpcenter-button"));
              dispatch(setModalSusAction(true));
            }}
          />
        ) : ""}

        {detailCustomer.buttonSuspend && detailCustomer.status === 0 ? (
          <>
            <ButtonGreen
              title={t("helpcenter.suspend_customer")}
              onClick={() => {
                dispatch(setModalSusCustomer(true));
              }}
            />
            <ButtonWhite
              title={t("helpcenter.suspend_merchant")}
              onClick={() => {
                dispatch(setModalSusMerchant(true));
              }}
            />
          </>
        ) : ""}
      </div>

      {modalReqResolved ? (
        <ConfirmationModal
          onCancel={({ showModal }) => {
            Action.changeModalReqResolved(dispatch, { showModal: showModal });
          }}
          title={t("helpcenter.confirm_req_resolved")}
          subtitle={t("helpcenter.subtitle_req_resolved")}
          onClick={({ remark, identifier }) => {
            Action.handleSubmitReqResolved(dispatch, {
              remarks: remark,
              id: detailCustomer.id,
              identifier: identifier,
            });
          }}
        />
      ) : null}

      {modalResolved ? (
        <ConfirmationModal
          onCancel={({ showModal }) => {
            Action.changeModalResolved(dispatch, { showModal: showModal });
          }}
          title={t("helpcenter.confirm_resolved")}
          subtitle={t("helpcenter.subtitle_resolved")}
          onClick={({ remark, identifier }) => {
            Action.handleSubmitResolved(dispatch, {
              remarks: remark,
              id: detailCustomer.id,
              identifier: identifier,
            });
          }}
        />
      ) : null}

      {modalSendEmail ? (
        <ConfirmationModal
          onCancel={({ showModal }) => {
            Action.changeModalSendEmail(dispatch, { showModal: showModal });
          }}
          title={t("management.confirm_sendemail")}
          subtitle={t("management.subtitle_sendemail")}
          onClick={({ remark }) => {
            Action.handleSubmitSendEmail({
              remarks: remark,
            });
          }}
        />
      ) : null}

      {modalSusAction ? (
        <ConfirmationSuspendModal
          t={t}
          title={t("helpcenter.confirm_req_suspend")}
          subtitle={t("helpcenter.subtitle_req_suspend", { type: "" })}
          onSubmit={() => {
            const data = { id: detailCustomer.id, identifier };
            Action.handleActionComplaint(dispatch, data);
          }}
          onCancel={() => {
            dispatch(setIdentifier(""));
            dispatch(setModalSusAction(false));
            dispatch(setErrorSusComplaint(""));
          }} />
      ) : ""}

      {modalSusMerchant ? (
        <ConfirmationSuspendModal
          t={t}
          title={t("helpcenter.confirm_req_suspend")}
          subtitle={t("helpcenter.subtitle_req_suspend", { type: "" })}
          onSubmit={() => {
            const data = { id: detailCustomer.id, status: "merchant" };
            Action.handleSuspendComplaint(dispatch, data);
          }}
          onCancel={() => {
            dispatch(setModalSusMerchant(false));
            dispatch(setErrorSusComplaint(""));
          }} />
      ) : ""}

      {modalSusCustomer ? (
        <ConfirmationSuspendModal
          t={t}
          title={t("helpcenter.confirm_req_suspend")}
          subtitle={t("helpcenter.subtitle_req_suspend", { type: "" })}
          onSubmit={() => {
            const data = { id: detailCustomer.id, status: "customer" };
            Action.handleSuspendComplaint(dispatch, data);
          }}
          onCancel={() => {
            dispatch(setModalSusCustomer(false));
            dispatch(setErrorSusComplaint(""));
          }} />
      ) : ""}
    </div>
  );
};

export default Detail;
