function BalanceListModel({
  id,
  invoiceNumber,
  dateTime,
  commission,
  applicationServiceFee,
  applicationServiceFeeInvoice,
  cancellationFee,
  withdrawalStatus,
  withdrawalAmount,
  withdrawalFee,
  type,
  total,
  merchantId,
  merchantName,
  noResi,
  shippingCost,
  shippingInsurance,
  products,
  totalPrice,
  totalPricePromo,
  totalShippingCostPromo,
  transactionServiceFee,
  orderDate,
  totalAdminPromo
}) {
  let obj = {};
  obj.id = id;
  obj.invoiceNumber = invoiceNumber;
  obj.dateTime = dateTime;
  obj.commission = commission;
  obj.applicationServiceFee = applicationServiceFee;
  obj.applicationServiceFeeInvoice = applicationServiceFeeInvoice;
  obj.cancellationFee = cancellationFee;
  obj.withdrawalStatus = withdrawalStatus;
  obj.withdrawalAmount = withdrawalAmount;
  obj.withdrawalFee = withdrawalFee;
  obj.type = type;
  obj.total = total;
  obj.merchantId = merchantId;
  obj.merchantName = merchantName;
  obj.noResi = noResi;
  obj.shippingCost = shippingCost;
  obj.shippingInsurance = shippingInsurance;
  obj.products = products;
  obj.totalPrice = totalPrice;
  obj.totalPricePromo = totalPricePromo;
  obj.totalShippingCostPromo = totalShippingCostPromo;
  obj.transactionServiceFee = transactionServiceFee;
  obj.orderDate = orderDate;
  obj.totalAdminPromo = totalAdminPromo
  return obj;
}

export default BalanceListModel;
