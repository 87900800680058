import { useDispatch, useSelector } from 'react-redux';
import Action from '../redux/Action';

const PromoForInput = () => {
    const dispatch = useDispatch();
    const formValue = useSelector(state => state.reducer.formValue);

    const handlePromoChange = async (name, value) => {
        Action.handleFormChange(dispatch, { name, value, formValue });
    };

    return (
        <div className="flex flex-row items-center gap-x-5">
            <div className="flex items-center">
                <input
                    checked={formValue.promoTarget === "EVERYONE"}
                    onChange={(e) => handlePromoChange(e.target.name, e.target.value)}
                    id="EVERYONE"
                    type="radio"
                    value="EVERYONE"
                    name="promoTarget"
                    className="w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary" />
                <label
                    htmlFor="EVERYONE"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Everyone
                </label>
            </div>
            <div className="flex items-center">
                <input
                    checked={formValue.promoTarget === "NEW_ACCOUNT"}
                    onChange={(e) => handlePromoChange(e.target.name, e.target.value)}
                    id="NEW_ACCOUNT"
                    type="radio"
                    value="NEW_ACCOUNT"
                    name="promoTarget"
                    className="w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary" />
                <label
                    htmlFor="NEW_ACCOUNT"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    New Account
                </label>
            </div>
            <div className="flex items-center">
                <input
                    checked={formValue.promoTarget === "SPECIFIC_USER"}
                    onChange={(e) => handlePromoChange(e.target.name, e.target.value)}
                    id="SPECIFIC_USER"
                    type="radio"
                    value="SPECIFIC_USER"
                    name="promoTarget"
                    className="w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary" />
                <label
                    htmlFor="SPECIFIC_USER"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Specific User
                </label>
            </div>
        </div>
    );
};

export default PromoForInput;
