import React, { useState } from 'react';
import DateRangePicker from '../../../../../components/DateRangePicker/DateRangePicker';
import { useTranslation } from 'react-multi-lang';
import DateUtility from '../../../../../utils/date-utility';
import ButtonWhite from '../../../../../components/ButtonWhite/ButtonWhite';
import moment from 'moment';
import axios from 'axios';
import Endpoint from '../../../../../services/endpoint';
import GeneralUtility from '../../../../../utils/general-utility';

const GenerateReportModal = ({ onCancel }) => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const t = useTranslation();

    const handleGenerate = async () => {
        try {
            const token = await GeneralUtility.getToken();
            const language = await GeneralUtility.getLanguage();
            const headers = {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "Accept-Language": language,
            };

            const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
            const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
            const diffDays = moment(formattedEndDate).diff(moment(formattedStartDate), 'days');
            const BASE_API = process.env.REACT_APP_BASE_API;
            const URL = `${BASE_API}${Endpoint.promoReport}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;

            if (diffDays >= 31) {
                setErrorMessage("Tidak boleh lebih dari 31 hari");
                return;
            } else if (endDate === "") {
                setErrorMessage("End date tidak boleh kosong");
                return;
            }



            const response = await axios.get(URL, {
                headers: headers, // Menambahkan headers ke request
                responseType: 'blob', // Pastikan responseType di set ke 'blob'
            });

            // Membuat URL dari response data
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const filename = `report-promo-${formattedStartDate}-&-${formattedEndDate}.xlsx`;
            link.href = url;
            link.setAttribute('download', filename); // Menetapkan nama file sesuai format
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            onCancel();
        } catch (error) {
            console.error('Error downloading the file', error);
        }
    };


    return (
        <>
            <div role="button" className='fixed inset-0 w-full h-full bg-black opacity-40 z-20'></div>
            <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[600px] p-5 z-30 bg-gray-primary rounded-xl'>
                <div className='flex justify-between items-center pb-5'>
                    <h4 className="opacity-60 font-bold">
                        Set Date Range
                    </h4>
                    <p className='font-medium'>
                        Max 31 Days
                    </p>
                </div>

                <div className='relative'>
                    <DateRangePicker
                        startDateValue={startDate}
                        endDateValue={endDate}
                        onOpen={() => {
                            setStartDate("");
                            setEndDate("");
                            setErrorMessage("");
                        }}
                        onChange={(dateObject) => {
                            var d0 = new Date(dateObject[0]);
                            if (dateObject.length > 1) {
                                var d1 = new Date(dateObject[1]);
                                setStartDate(d0.toISOString());
                                setEndDate(d1.toISOString());
                            } else {
                                setStartDate(d0.toISOString());
                                setEndDate("");
                            }
                        }}
                        format="DD MMM YYYY"
                        onClose={() => { }}
                        placeholder={t("buyback.date_range_placeholder")}
                        inputClass={`w-full block sm:text-sm shadow-sm border border-white rounded-xl bg-white placeholder:text-slate-40 cursor-pointer `}
                        containerClassName="w-full" />
                    <img src={process.env.REACT_APP_ASSETS_IMAGE + "calendar-icon.png"}
                        className='absolute top-1/2 right-4 -translate-y-1/2 w-5'
                        alt='' role='button' />
                </div>
                {errorMessage && <p className='text-red-500 text-xs mt-2'>{errorMessage}</p>}

                <div className='pt-5 flex justify-between items-center'>
                    <p>{startDate && endDate ? DateUtility.formatDate(startDate, "half") + " - " + DateUtility.formatDate(endDate, "half") : ""}</p>
                    <div className='flex items-center gap-2'>
                        <ButtonWhite
                            title={t("general.cancel").toUpperCase()}
                            onClick={onCancel}
                        />
                        <button
                            disabled={startDate === "" && endDate === "" || errorMessage}
                            onClick={handleGenerate}
                            className={`
                            ${startDate === "" && endDate === "" ? "opacity-40 cursor-not-allowed" : "cursor-pointer"}
                            button md:w-36 hover:bg-gray-primary hover:text-green-secondary text-xs tracking-widest font-bold text-gray-primary border-2 border-green-secondary bg-green-secondary border-opacity-40 rounded-xl py-2`}>
                            {"generate".toUpperCase()}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GenerateReportModal;

