import moment from "moment";
import ManagementCustomerRepository from "../../../../../../../repositories/ManagementCustomerRepository";
import ManagementPromoRepository from "../../../../../../../repositories/ManagementPromoRepository";
import PopupUtility from "../../../../../../../utils/popup-utility";
import { DEFAULT_VALUE_FORM, setCurrentPageCustomer, setDisableButton, setErrors, setFormMode, setFormValue, setListCustomer, setModalTableCustomer, setPostPerPageCustomer, setSearchCustomer, setSelectedCustomers, setTemporaryFormValue, setTotalDataCustomer, setTotalPageCustomer } from "./Reducer";
import RouteName from "../../../../../../../services/routename";

const handleModalCustomer = (dispatch, boolean) => {
    dispatch(setModalTableCustomer(boolean));
};

const getListCustomers = async (dispatch, payload) => {
    dispatch(setCurrentPageCustomer(payload.page));
    dispatch(setPostPerPageCustomer(payload.size));
    dispatch(setSearchCustomer(payload.search));

    let response = await ManagementCustomerRepository.getList(payload);

    const newList = response.data.map(item => ({
        id: item.id,
        customerName: item.name || "-",
        customerEmail: item.email || "-"
    }));

    if (!response.error) {
        dispatch(setListCustomer(newList));
        dispatch(setTotalPageCustomer(response.total_page));
        dispatch(setTotalDataCustomer(response.total_data));
    } else {
        dispatch(setListCustomer([]));
        dispatch(setTotalPageCustomer(0));
        dispatch(setTotalDataCustomer(0));
    }
};

const getMerchantList = async (payload) => {
    let response = await ManagementCustomerRepository.getMerchantList(payload);

    if (!response.error) {
        const newList = response.data.map(item => ({
            value: item.id,
            label: item.name
        }));
        return newList;
    }

    return [];
};

const handleFormChange = (dispatch, { name, value, formValue }) => {
    const updatedFormValue = {
        ...formValue,
        [name]: value
    };
    dispatch(setFormValue(updatedFormValue));
};

const validateForm = (dispatch, formValue) => {
    return new Promise((resolve, reject) => {
        let errors = {};

        if (!formValue.promoName) {
            errors.promoName = "Promo Name cannot be empty";
        } else if (formValue.promoName.length > 50) {
            errors.promoName = "Promo Name can have a maximum of 50 characters";
        }

        if (!formValue.promoCode) {
            errors.promoCode = "Promo Code cannot be empty";
        } else if (formValue.promoCode.length > 15) {
            errors.promoCode = "Promo Code can have a maximum of 15 characters";
        }

        if (formValue.promoType === "PERCENTAGE") {
            if (!formValue.amount) {
                errors.amount = "Amount cannot be empty for promo type PERCENTAGE";
            } else if (formValue.amount > 100) {
                errors.amount = "Amount cannot be more than 100%";
            }
        }

        if (formValue.promoType === "FIXED_AMOUNT" && !formValue.discountAmount) {
            errors.discountAmount = "Discount Amount cannot be empty";
        } else if (formValue.promoType === "FIXED_AMOUNT" && formValue.discountAmount <= 1) {
            errors.discountAmount = "Discount Amount must be greater than 1 Rupiah";
        } else if (formValue.promoType === "FIXED_AMOUNT" && parseFloat(formValue.discountAmount.replace(',', '')) > parseFloat(formValue.minimumTransaction.replace(',', ''))) {
            errors.minimumTransaction = "Minimum Transaction must be greater than Discount Amount";
        }

        if (formValue.promoCharge !== "ADMIN" && !formValue.merchant) {
            errors.merchant = "Merchant cannot be empty for promo charge MERCHANT";
        }

        if (formValue.promoCharge === "ADMIN_AND_MERCHANT") {
            if (!formValue.merchant) {
                errors.merchant = "Merchant cannot be empty for promo charge ADMIN_AND_MERCHANT";
            }
            if (!formValue.chargePercentage) {
                errors.chargePercentage = "Charge Percentage cannot be empty for promo charge ADMIN_AND_MERCHANT";
            } else if (formValue.chargePercentage > 100) {
                errors.chargePercentage = "Charge Percentage cannot be more than 100%";
            }
        }

        if (!formValue.date.startDate && !formValue.date.endDate) {
            errors.promoDate = "Promo Date cannot be empty";
        } else if (formValue.date.endDate === "") {
            errors.promoDate = "End Date cannot be empty";
        }

        if (formValue.promoType === "PERCENTAGE" && formValue.includeMaxAmount && !formValue.maxDiscountAmount) {
            errors.maxDiscountAmount = "Max Discount Amount cannot be empty";
        } else if (formValue.promoType === "PERCENTAGE" && formValue.includeMaxAmount && parseFloat(formValue.maxDiscountAmount.replace(',', '')) > parseFloat(formValue.minimumTransaction.replace(',', ''))) {
            errors.minimumTransaction = "Minimum Transaction must be greater than Max. Discount Amount";
        }

        if (!formValue.discountAppliesTo) {
            errors.discountAppliesTo = "Discount Applies To cannot be empty";
        }

        if (!formValue.quota) {
            errors.quota = "Promo Quota cannot be empty";
        }

        if (!formValue.description) {
            errors.description = "Description cannot be empty";
        } else if (formValue.description.length < 20 || formValue.description.length > 300) {
            errors.description = "Description must be between 20 and 300 characters";
        }

        dispatch(setErrors(errors));
        let countError = Object.keys(errors).length;
        resolve(countError);
    });
};

const getRandomCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let newCode = '';
    for (let i = 0; i < 10; i++) {
        newCode += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return newCode;
};

const generateNewCode = (dispatch, formValue) => {
    const randomCode = getRandomCode();
    dispatch(setFormValue({ ...formValue, promoCode: randomCode }));
};

const handleSubmitForm = async ({ dispatch, formValue, selectedCustomers, navigate, cookiePromise }) => {
    const customerIds = selectedCustomers.map(item => item.id);
    const payload = {
        "type": formValue.promoType,
        "name": formValue.promoName,
        "code": formValue.promoCode,
        "startDate": moment(formValue.date.startDate).format("YYYY-MM-DD"),
        "endDate": moment(formValue.date.endDate).format("YYYY-MM-DD"),
        "charge": formValue.promoCharge,
        "merchantId": formValue.promoCharge !== "ADMIN" ? formValue.merchant?.value : null,
        "chargePercentage": formValue.promoCharge === "ADMIN_AND_MERCHANT" ? parseInt(formValue.chargePercentage) : 0,
        "target": formValue.promoTarget,
        "customerIds": customerIds,
        "discountAmount": formValue.promoType === "FIXED_AMOUNT" ? parseInt(formValue.discountAmount) : null,
        "discountPercentage": formValue.promoType === "PERCENTAGE" ? parseInt(formValue.amount) : null,
        "includeMaxAmount": formValue.includeMaxAmount,
        "maxDiscountAmount": formValue.includeMaxAmount ? parseFloat(formValue.maxDiscountAmount) : null,
        "minimumTransaction": parseFloat(formValue.minimumTransaction) || 0,
        "discountAppliesTo": formValue.discountAppliesTo,
        "quota": parseInt(formValue.quota),
        "description": formValue.description
    };
    dispatch(setDisableButton(true));
    let response = await ManagementPromoRepository.create(payload);

    if (!response.error) {
        dispatch(setFormValue(DEFAULT_VALUE_FORM));
        dispatch(setTemporaryFormValue(DEFAULT_VALUE_FORM));
        dispatch(setSelectedCustomers([]));
        dispatch(setErrors({}));
        await cookiePromise();

        navigate(RouteName.management);
        return PopupUtility.success("Successfully created promo!");
    }

    dispatch(setDisableButton(false));
    const message = `
        <p class="text-lg mb-3 font-extrabold">${response.message}</p>
        <p class="text-sm font-bold text-gray-400 tracking-wider">Trace Id : ${response.trace_id}</p>
    `;
    PopupUtility.responseValidate("Error", message);
};

const changeEditFormat = (detailPromo, navigate) => {
    const payload = {
        id: detailPromo.id,
        promoType: detailPromo.type,
        promoName: detailPromo.name,
        promoCode: detailPromo.code,
        date: {
            startDate: new Date(detailPromo.startDate).toISOString(),
            endDate: new Date(detailPromo.endDate).toISOString()
        },
        promoCharge: detailPromo.charge,
        merchant: detailPromo.merchant && Object.keys(detailPromo.merchant).length > 0 ? {
            value: detailPromo.merchant.id,
            label: detailPromo.merchant.name
        } : null,
        chargePercentage: detailPromo.chargePercentage,
        promoTarget: detailPromo.target,
        amount: detailPromo.discountPercentage,
        includeMaxAmount: parseFloat(detailPromo.maxDiscountAmount.replace(',', '')) > 0 ? true : false,
        discountAmount: detailPromo.discountAmount,
        maxDiscountAmount: parseFloat(detailPromo.maxDiscountAmount.replace(',', '')) > 0 ? detailPromo.maxDiscountAmount : "",
        minimumTransaction: detailPromo.minimumTransaction,
        discountAppliesTo: detailPromo.discountAppliesTo,
        quota: detailPromo.quota.toString(),
        description: detailPromo.description,
        customers: detailPromo.customers
    };
    navigate(RouteName.promo_form, { state: payload });
};

const handleUpdateForm = async ({ dispatch, formValue, selectedCustomers, navigate, cookiePromise, temporaryFormValue }) => {
    const customers = selectedCustomers.filter(item => item.action !== undefined).map(item2 => ({
        id: item2.id,
        action: item2.action
    }));
    const promoId = formValue.id;

    // Function to check if two values are different
    const isDifferent = (key) => {
        if (typeof formValue[key] === 'object' && formValue[key] !== null) {
            return JSON.stringify(formValue[key]) !== JSON.stringify(temporaryFormValue[key]);
        }
        return formValue[key] !== temporaryFormValue[key];
    };

    let payload = {};

    if (isDifferent('promoType')) payload.type = formValue.promoType;
    if (isDifferent('promoName')) payload.name = formValue.promoName;
    if (isDifferent('promoCode')) payload.code = formValue.promoCode;
    if (isDifferent('date')) {
        payload.startDate = moment(formValue.date.startDate).format("YYYY-MM-DD");
        payload.endDate = moment(formValue.date.endDate).format("YYYY-MM-DD");
    }
    if (isDifferent('promoCharge')) payload.charge = formValue.promoCharge;
    if (formValue.promoCharge !== "ADMIN" && isDifferent('merchant')) {
        payload.merchantId = formValue.merchant?.value;
    }
    if (formValue.promoCharge === "ADMIN_AND_MERCHANT" && isDifferent('chargePercentage')) {
        payload.chargePercentage = parseInt(formValue.chargePercentage);
    }
    if (isDifferent('promoTarget')) payload.target = formValue.promoTarget;
    if (customers.length > 0) payload.customers = customers;
    if (formValue.promoType === "FIXED_AMOUNT" && isDifferent('discountAmount')) {
        payload.discountAmount = parseInt(formValue.discountAmount);
    }
    if (formValue.promoType === "PERCENTAGE" && isDifferent('amount')) {
        payload.discountPercentage = parseInt(formValue.amount);
    }
    if (isDifferent('includeMaxAmount')) payload.includeMaxAmount = formValue.includeMaxAmount;
    if (formValue.includeMaxAmount && isDifferent('maxDiscountAmount')) {
        payload.maxDiscountAmount = parseFloat(formValue.maxDiscountAmount);
    }
    if (isDifferent('minimumTransaction')) {
        payload.minimumTransaction = parseFloat(formValue.minimumTransaction) || 0;
    }
    if (isDifferent('discountAppliesTo')) payload.discountAppliesTo = formValue.discountAppliesTo;
    if (isDifferent('quota')) payload.quota = parseInt(formValue.quota);
    if (isDifferent('description')) payload.description = formValue.description;

    dispatch(setDisableButton(true));
    
    let response = await ManagementPromoRepository.update(payload, promoId);

    if (!response.error) {
        dispatch(setFormValue(DEFAULT_VALUE_FORM));
        dispatch(setTemporaryFormValue(DEFAULT_VALUE_FORM));
        dispatch(setSelectedCustomers([]));
        dispatch(setErrors({}));
        await cookiePromise();

        navigate(RouteName.management);
        return PopupUtility.success("Successfully updated promo!");
    }

    dispatch(setDisableButton(false));
    const message = `
        <p class="text-lg mb-3 font-extrabold">${response.message}</p>
        <p class="text-sm font-bold text-gray-400 tracking-wider">Trace Id : ${response.trace_id}</p>
    `;
    PopupUtility.responseValidate("Error", message);
};


const Action = {
    handleModalCustomer,
    getListCustomers,
    handleFormChange,
    validateForm,
    generateNewCode,
    getMerchantList,
    handleSubmitForm,
    changeEditFormat,
    handleUpdateForm
};

export default Action;