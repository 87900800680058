function TransactionHistoryListModel({
    id,
    invoiceNumber,
    purchaseDate,
    customerName,
    merchantName,
    status
}) {
    let obj = {};
    obj.id = id;
    obj.invoiceNumber = invoiceNumber;
    obj.purchaseDate = purchaseDate;
    obj.customerName = customerName;
    obj.merchantName = merchantName;
    obj.status = status;
    return obj;
}

export default TransactionHistoryListModel;