import HelpCenterRepository from "../../../../../repositories/HelpCenterRepository";
import PopupUtility from "../../../../../utils/popup-utility";
import {
  setCurrentPage,
  setList,
  setDetailCustomer,
  setLoading,
  setModalReqResolved,
  setModalResolved,
  setModalSendEmail,
  setPostPerPage,
  setShowDetail,
  setTotalData,
  setSearch,
  setFieldName,
  setOrderBy,
  setType,
  setTotalPage,
  setModalSusCustomer,
  setModalSusMerchant,
  setErrorSusComplaint,
  setStatusFilter,
  setModalSusAction,
  setIdentifier,
} from "./Reducer";

const getList = async (
  dispatch,
  { currentPage, postPerPage, search, statusFilter, type, fieldName, orderBy }
) => {
  dispatch(setType(type));
  dispatch(setLoading(true));
  dispatch(setCurrentPage(currentPage));
  dispatch(setPostPerPage(postPerPage));
  dispatch(setSearch(search));
  dispatch(setFieldName(fieldName));
  dispatch(setOrderBy(orderBy));
  dispatch(setStatusFilter(statusFilter));

  let data = {
    page: currentPage,
    search: search || "",
    status: statusFilter || "",
    size: postPerPage || 5,
    fieldName: fieldName || "",
    orderBy: orderBy || "",
    type: "customer"
  };

  let response = await HelpCenterRepository.getCustomer(data);

  if (response.error === false) {
    dispatch(setLoading(false));
    dispatch(setList(response.data));
    dispatch(setTotalData(response.total_data));
    dispatch(setTotalPage(response.total_pages));
  } else {
    dispatch(setLoading(true));
    dispatch(setList([]));
    dispatch(setTotalData(0));
    dispatch(setTotalPage(0));
  }
};

const setPage = async (dispatch, pages) => {
  dispatch(setCurrentPage(pages));
  getList(dispatch, { page: pages });
};

const changeShowDetail = async (dispatch, showDetail, id) => {
  if (id) {
    const response = await HelpCenterRepository.getDetail({ id: id });
    const resDetail = response.data.data;

    if (response.error === false) {
      dispatch(setDetailCustomer(resDetail));
    } else {
      dispatch(setDetailCustomer({}));
    }
  } else {
    dispatch(setDetailCustomer({}));
  }
  dispatch(setShowDetail(showDetail));
};

const changeModalResolved = async (dispatch, { showModal }) => {
  dispatch(setModalResolved(showModal));
};

const changeModalReqResolved = async (dispatch, { showModal }) => {
  dispatch(setModalReqResolved(showModal));
};

const changeModalSendEmail = async (dispatch, { showModal }) => {
  dispatch(setModalSendEmail(showModal));
};

const handleSubmitResolved = async (dispatch, { id, remarks }) => {
  let data = {
    id: id,
    remarks: remarks,
    identifier: "setasresolved-customer-helpcenter-button",
  };
  let response = await HelpCenterRepository.changeStatusFeedback(data);

  if (!response.error) {
    PopupUtility.success(response.message);
    getList(dispatch, { currentPage: 1, postPerPage: 5 });
    dispatch(setShowDetail(false));
    dispatch(setModalResolved(""));
  }
};

const handleSubmitReqResolved = async (dispatch, { id, remarks }) => {
  let data = {
    id: id,
    remarks: remarks,
    identifier: "reqresolve-customer-helpcenter-button",
  };
  let response = await HelpCenterRepository.changeStatusFeedback(data);

  if (!response.error) {
    PopupUtility.success(response.message);
    getList(dispatch, { currentPage: 1, postPerPage: 5 });
    dispatch(setShowDetail(false));
    dispatch(setModalReqResolved(""));
  } else {
    PopupUtility.responseValidate("Failed", response.message);
  }
};

const handleSuspendComplaint = async (dispatch, { id, status }) => {
  const data = { id };
  let response = await HelpCenterRepository.suspendComplaint(data, status);

  if (!response.error) {
    PopupUtility.success("Successfully set suspend!");
    getList(dispatch, {
      currentPage: 0,
      postPerPage: 5,
      fieldName: "issuedDate",
      orderBy: "desc",
      search: "",
      statusFilter: ""
    });
    dispatch(setModalSusCustomer(false));
    dispatch(setModalSusMerchant(false));
    dispatch(setErrorSusComplaint(""));
    dispatch(setShowDetail(false));
    dispatch(setDetailCustomer({}));
  } else {
    dispatch(setErrorSusComplaint(response.message));
  }


};

const handleActionComplaint = async (dispatch, data) => {
  let response = await HelpCenterRepository.actionComplaint(data);

  if (!response.error) {
    PopupUtility.success(response.message);
    getList(dispatch, {
      currentPage: 0,
      postPerPage: 5,
      fieldName: "issuedDate",
      orderBy: "desc",
      search: "",
      statusFilter: ""
    });
    dispatch(setModalSusAction(false));
    dispatch(setErrorSusComplaint(""));
    dispatch(setShowDetail(false));
    dispatch(setIdentifier(""));
    dispatch(setDetailCustomer({}));
  } else {
    dispatch(setErrorSusComplaint(response.message));
  }
};

const Action = {
  getList,
  setPage,
  changeShowDetail,
  changeModalResolved,
  changeModalReqResolved,
  changeModalSendEmail,
  handleSubmitResolved,
  handleSubmitReqResolved,
  handleSuspendComplaint,
  handleActionComplaint
};

export default Action;
