import React from 'react';
import ButtonWhite from '../../../../../components/ButtonWhite/ButtonWhite';
import ButtonGreen from '../../../../../components/ButtonGreen/ButtonGreen';
import { useSelector } from 'react-redux';

const ConfirmationSuspendModal = ({ t, onCancel, title, subtitle, onSubmit }) => {
    const errorSusComplaint = useSelector(state => state.reducer.errorSusComplaint);

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={onCancel}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-gray-primary rounded-xl shadow-lg">
                    <div className="m-3 text-left">
                        <div className='flex items-start'>
                            <img
                                className="h-6 w-6 block mr-2 mt-1"
                                src={process.env.REACT_APP_ASSETS_IMAGE + "caution-icon.svg"}
                                alt="caution"
                            />
                            <div>
                                <h3 className="text-green-primary font-bold pb-2 flex items-center">
                                    {title}
                                </h3>
                                <div className=" text-sm">{subtitle}</div>

                                {errorSusComplaint !== "" ? (
                                    <div className='mt-1 text-red-500'>{errorSusComplaint}</div>
                                ) : ""}
                            </div>
                        </div>



                        <div className='mt-5'></div>

                        <div className="items-center gap-2 mt-3 flex justify-end">
                            <ButtonWhite
                                title={t("general.cancel")}
                                onClick={onCancel}
                            />
                            <ButtonGreen
                                title={t("general.confirm")}
                                onClick={onSubmit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationSuspendModal;