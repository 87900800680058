import { createSlice } from "@reduxjs/toolkit";

export const DEFAULT_VALUE_FORM = {
    promoType: "FIXED_AMOUNT",
    promoName: "",
    promoCode: "",
    date: {
        startDate: "",
        endDate: ""
    },
    promoCharge: "ADMIN",
    merchant: null,
    chargePercentage: "",
    promoTarget: "EVERYONE",
    amount: "",
    includeMaxAmount: false,
    discountAmount: "",
    maxDiscountAmount: "",
    minimumTransaction: "",
    discountAppliesTo: "",
    quota: "",
    description: ""
};

const reducerSlice = createSlice({
    name: "ManagementPromoForm",
    initialState: {
        modalTableCustomer: false,
        postPerPageCustomer: 5,
        searchCustomer: "",
        currentPageCustomer: 0,
        totalPageCustomer: 0,
        totalDataCustomer: 0,
        listCustomer: [],
        selectedCustomers: [],
        formValue: DEFAULT_VALUE_FORM,
        temporaryFormValue: DEFAULT_VALUE_FORM,
        errors: {},
        merchantList: [],
        formMode: "ADD",
        disableButton: false
    },
    reducers: {
        setModalTableCustomer: (state, action) => {
            state.modalTableCustomer = action.payload;
        },
        setPostPerPageCustomer: (state, action) => {
            state.postPerPageCustomer = action.payload;
        },
        setSearchCustomer: (state, action) => {
            state.searchCustomer = action.payload;
        },
        setCurrentPageCustomer: (state, action) => {
            state.currentPageCustomer = action.payload;
        },
        setTotalPageCustomer: (state, action) => {
            state.totalPageCustomer = action.payload;
        },
        setTotalDataCustomer: (state, action) => {
            state.totalDataCustomer = action.payload;
        },
        setListCustomer: (state, action) => {
            state.listCustomer = action.payload;
        },
        setSelectedCustomers: (state, action) => {
            state.selectedCustomers = action.payload;
        },
        setFormValue: (state, action) => {
            state.formValue = action.payload;
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
        setMerchantList: (state, action) => {
            state.merchantList = action.payload;
        },
        setFormMode: (state, action) => {
            state.formMode = action.payload;
        },
        setTemporaryFormValue: (state, action) => {
            state.temporaryFormValue = action.payload;
        },
        setDisableButton: (state, action) => {
            state.disableButton = action.payload;
        }
    }
});

export const {
    setModalTableCustomer,
    setPostPerPageCustomer,
    setSearchCustomer,
    setCurrentPageCustomer,
    setTotalDataCustomer,
    setTotalPageCustomer,
    setListCustomer,
    setSelectedCustomers,
    setFormValue,
    setErrors,
    setMerchantList,
    setFormMode,
    setTemporaryFormValue,
    setDisableButton
} = reducerSlice.actions;

export default reducerSlice;