import React, { useEffect, useState } from "react";
import { useTranslation } from "react-multi-lang";
import ButtonGold from "../../../components/ButtonGold/ButtonGold";
import { useLocation, useNavigate } from "react-router-dom";
import RouteName from "../../../services/routename";
import UseAuth from "../Auth";

const ScanQRPassword = () => {
  const location = useLocation();
  const email = location.state?.email;

  const navigate = useNavigate();
  const t = useTranslation();

  const [scanQRPassword, setScanQRPassword] = useState("");

  const handleButtonClick = () => {
    navigate(RouteName.authforgotpassword, {state: {email}})
  };

  useEffect(() => {
    UseAuth.generateQRPassword(setScanQRPassword, email)
  }, [])
  return (
    <>
      <div className="section py-10 px-6 lg:mx-20 md:mx-28">
        <form className="font-medium">
          <div className="pl-2 pt-4">
            <h2 className="text-green-secondary font-bold">
              {t("login.scanqrcode")}
            </h2>
            <h3>{t("login.subtitlescanqrcode")}</h3>
          </div>

          <div className="mt-10 bg-white rounded-xl p-1 w-auto mx-auto h-64 max-w-[250px]">
            <img
              src={`data:image/png;base64, ${scanQRPassword}`}
              alt="QR Code"
              className="p-[6px] w-full h-full"
            />
          </div>

          <div className="pt-12 text-right">
            <ButtonGold
              title={t("login.scanqrnext").toUpperCase()}
              onClick={handleButtonClick}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default ScanQRPassword;
