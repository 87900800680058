import React from 'react';
import { useTranslation } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import ButtonGreen from '../../../../../components/ButtonGreen/ButtonGreen';
import Action from '../redux/Action';
import ButtonWhite from '../../../../../components/ButtonWhite/ButtonWhite';
import UseAuth from '../../../../auth/Auth';

const DetailPromo = () => {
    const t = useTranslation();
    const dispatch = useDispatch();

    const detailPromo = useSelector(state => state.reducer.detailPromo);
    const disabledButton = useSelector(state => state.reducer.disabledButton);
    const typeDisableButton = useSelector(state => state.reducer.typeDisableButton);

    return (
        <div className="font-medium text-sm">
            <div className="flex flex-row items-center pb-4">
                <h4 className="flex-1 opacity-60 font-bold">
                    DETAILS
                </h4>
            </div>

            <div className="h-72 overflow-y-auto">
                <div className="flex flex-row items-right pb-2 w-full">
                    <div className="flex-1">
                        <p className="text-green-quaternary">
                            {t("management.promo_id")}
                        </p>
                        <p className="font-bold text-md">{detailPromo.promoId}</p>
                    </div>
                    <div className="text-right">
                        <div className="min-w-fit py-1 px-4 text-center text-green-primary font-bold rounded-xl border-2 border-green-secondary border-opacity-40">
                            {"Request Approval"}
                        </div>
                    </div>
                </div>

                <div className='flex items-center flex-wrap'>
                    {/* promo name */}
                    <div className="pb-2 w-1/2">
                        <p className="text-green-quaternary">
                            {t("management.promo_name")}
                        </p>
                        <p className="font-bold text-md">
                            {detailPromo.promoName}
                        </p>
                    </div>

                    {/* promo code */}
                    <div className="pb-2 w-1/2">
                        <p className="text-green-quaternary">
                            {t("management.promo_code")}
                        </p>
                        <p className="font-bold text-md">
                            {detailPromo.promoCode}
                        </p>
                    </div>

                    {/* promo charge */}
                    <div className="pb-2 w-1/2">
                        <p className="text-green-quaternary">
                            {t("management.promo_charge")}
                        </p>
                        <p className="font-bold text-md">
                            {detailPromo.promoCharge}
                        </p>
                    </div>

                    {/* merchant name */}
                    {detailPromo.charge !== "ADMIN" ? (
                        <div className="pb-2 w-1/2">
                            <p className="text-green-quaternary">
                                {t("management.merchant_name")}
                            </p>
                            <p className="font-bold text-md">
                                {detailPromo.merchant?.name}
                            </p>
                        </div>
                    ) : ""}

                    {/* charge percentage */}
                    {detailPromo.charge === "ADMIN_MERCHANT" ? (
                        <div className="pb-2 w-1/2">
                            <p className="text-green-quaternary">
                                {t("management.charge_percentage")}
                            </p>
                            <p className="font-bold text-md">
                                {detailPromo.chargePercentage}%
                            </p>
                        </div>
                    ) : ""}

                    {/* promo type */}
                    <div className="pb-2 w-1/2">
                        <p className="text-green-quaternary">
                            {t("management.promo_type")}
                        </p>
                        <p className="font-bold text-md">
                            {detailPromo.promoType}
                        </p>
                    </div>

                    {/* promo percentage */}
                    {detailPromo.type === "PERCENTAGE" ? (
                        <div className="pb-2 w-1/2">
                            <p className="text-green-quaternary">
                                {t("management.promo_percentage")}
                            </p>
                            <p className="font-bold text-md">
                                {detailPromo.discountPercentage}%
                            </p>
                        </div>
                    ) : ""}

                    {/* discount amount */}
                    {detailPromo.type === "FIXED_AMOUNT" ? (
                        <div className="pb-2 w-1/2">
                            <p className="text-green-quaternary">
                                {t("management.discount_amount")}
                            </p>
                            <p className="font-bold text-md">
                                {detailPromo.discountAmount}
                            </p>
                        </div>
                    ) : ""}

                    {/* max promo amount */}
                    {detailPromo.includeMaxAmount ? (
                        <div className="pb-2 w-1/2">
                            <p className="text-green-quaternary">
                                {t("management.max_promo_amount")}
                            </p>
                            <p className="font-bold text-md">
                                Rp {detailPromo.maxDiscountAmount}
                            </p>
                        </div>
                    ) : ""}

                    {/* minimum transaction amount */}
                    <div className="pb-2 w-1/2">
                        <p className="text-green-quaternary">
                            {t("management.minimum_transaction_amount")}
                        </p>
                        <p className="font-bold text-md">
                            Rp {detailPromo.minimumTransaction}
                        </p>
                    </div>

                    {/* promo target */}
                    <div className="pb-2 w-1/2">
                        <p className="text-green-quaternary">
                            {t("management.promo_target")}
                        </p>
                        <p className="font-bold text-md">
                            {detailPromo.promoTarget}
                        </p>
                    </div>

                    {/* customer list PENDING */}
                    {detailPromo.target === "SPECIFIC_USER" ? (
                        <div className="pb-2 w-1/2">
                            <p className="text-green-quaternary">
                                {t("management.specific_user")}
                            </p>
                            <p className="font-bold text-md underline w-fit" role='button'>
                                {detailPromo.totalSpecificUser} Account(s)
                            </p>
                        </div>
                    ) : ""}

                    {/* promo quota */}
                    <div className="pb-2 w-1/2">
                        <p className="text-green-quaternary">
                            {t("management.promo_quota")}
                        </p>
                        <p className="font-bold text-md">
                            {detailPromo.quota}
                        </p>
                    </div>

                    {/* promotion date */}
                    <div className="pb-2 w-1/2">
                        <p className="text-green-quaternary">
                            {t("management.promotion_date")}
                        </p>
                        <p className="font-bold text-sm">
                            {detailPromo.date}
                        </p>
                    </div>

                    {/* discount applies to */}
                    <div className="pb-2 w-1/2">
                        <p className="text-green-quaternary">
                            {t("management.discount_applies_to")}
                        </p>
                        <p className="font-bold text-md">
                            {detailPromo.promoDiscountAppliesTo}
                        </p>
                    </div>

                    {/* description */}
                    <div className="pt-2 w-full max-w-[320px] overflow-auto">
                        <p className="text-green-quaternary">
                            {t("management.description")}
                        </p>
                        <p className="font-bold text-md w-full max-w-[320px] overflow-x-auto">
                            {detailPromo.description}
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-row-reverse gap-x-2 pt-2">
                {UseAuth.checkFunction("approve-promo-management-promo-button", "button") === true ?
                    <ButtonGreen
                        title={t("general.accepted")}
                        disabled={typeDisableButton === "accepted" && disabledButton}
                        onClick={() => {
                            Action.confirmPromo({
                                type: "accepted",
                                promoId: detailPromo.id,
                                dispatch
                            });
                        }} />
                    : null}

                {UseAuth.checkFunction("decline-promo-management-promo-button", "button") === true ?
                    <ButtonWhite
                        title={t("general.declined")}
                        disabled={typeDisableButton === "declined" && disabledButton}
                        onClick={() => {
                            Action.confirmPromo({
                                type: "declined",
                                promoId: detailPromo.id,
                                dispatch
                            });
                        }} />
                    : null}

            </div>
        </div>
    );
};

export default DetailPromo;