import Cookies from "js-cookie";
import EncryptDecrypt from "../services/encrypt-decrypt";
import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";
import axios from "axios";
import UseAuth from "../pages/auth/Auth";
import RouteName from "../services/routename";

const BASE_API = process.env.REACT_APP_BASE_API;

const getAuth = async (dat) => {
  const url = BASE_API + Endpoint.login;
  const response = await CallApi({ url, method: "POST", data: dat });
  return response;
};

const getEmail = async (data) => {
  const url = BASE_API + Endpoint.forgotPassword;
  const response = await CallApi({
    url,
    method: "POST",
    data
  });
  return response;
};

const resetPassword = async (data) => {
  const url = BASE_API + Endpoint.resetPassword;
  const response = await CallApi({ url, method: "POST", data });
  return response;
};

const refreshToken = async () => {
  const getToken = Cookies.get("refreshToken");
  const refreshToken =
    getToken !== undefined ? EncryptDecrypt.dec(getToken) : null;
  const url = BASE_API + Endpoint.refreshToken;
  const response = await CallApi({
    url,
    method: "POST",
    data: { refreshToken: refreshToken },
  });

  const data = {
    error: response.error,
    message: response.message,
    token: response.data.data.auth.token,
    refreshToken: response.data.data.auth.refreshToken,
  };

  return data;
};

const getStatusMaintentance = async () => {
  // const url = BASE_API + Endpoint.statusMaintenance;
  // const response = await CallApi({ url, method: "POST" });
  // const response1 = typeof response != "undefined" ? response.data : null;
  // const response2 = typeof response1 != "undefined" && response1 != null ? response1.data : null;

  const responseStatus = await axios({
    url: BASE_API + Endpoint.statusMaintenance,
    method: "POST"
  });

  const cookieMaintenance = Cookies.get("status_maintenance");
  const isMaintenance = cookieMaintenance ? EncryptDecrypt.dec(cookieMaintenance) : null;
  if (!isMaintenance) {
    const status = responseStatus.data.data;
    const currentPath = window.location.pathname;
    if (status === false && currentPath !== RouteName.freezemode) {
      UseAuth.removeAllInternalStorage();
      window.location.href = RouteName.freezemode;
      return;
    }
    return status;
  }
};





const generateQR = async (data) => {
  const url = BASE_API + Endpoint.generateQR2fa;
  const response = await CallApi({url, method: "POST", data});
  const response1 = typeof response != "undefined" ? response.data : null;
  const response2 = typeof response1 != "undefined" && response1 != null ? response1.data : [];
  return response1;
}

const validate2faCode = async (data) => {
  const url = BASE_API + Endpoint.validate2faCode;
  const response = await CallApi({url, method: "POST", data})
  return response;
}

const validateEmail = async (data) => {
  const url = BASE_API + Endpoint.validateEmail;
  const response = await CallApi({url, method: "POST", data})
  const response2 = typeof response != "undefined" ? response.data : null;
  const response3 = typeof response2 != "undefined" && response2 != null ? response2.data : [];
  
  return response3;
}

const AuthRepository = {
  getAuth,
  getEmail,
  resetPassword,
  refreshToken,
  generateQR,
  validate2faCode,
  validateEmail,
  getStatusMaintentance
};

export default AuthRepository;
