import React from "react";

const Privacy = () => {
  return (
    <div className="bg-white text-black h-full">
      <div className="flex justify-center">
        <div className="lg:w-1/2 sm:w-3/4 md:w-full mx-5 my-10">
          <h1 className="font-bold mb-7 lg:text-4xl text-3xl">
            Privacy & Policy
          </h1>
          <h4>Last updated: August 21, 2023</h4>
          <br />
          <h4>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </h4>
          <br />
          <h4>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the Privacy Policy
            Template.
          </h4>
          <br />
          <h2 className="font-semibold">Interpretation and Definitions</h2>
          <br />
          <h3>Interpretation</h3>
          <br />
          <h4>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </h4>
          <br />
          <h3>Definitions</h3>
          <br />
          <h4>For the purposes of this Privacy Policy:</h4>
          <ul>
            <li>
              - Account means a unique account created for You to access our
              Service or parts of our Service.
            </li>
            <li>
              - Company (referred to as either "the Company", "We", "Us" or
              "Our" in this Agreement) refers to Lice Term.
            </li>
            <li>
              - Cookies are small files that are placed on Your computer, mobile
              device or any other device by a website, containing the details of
              Your browsing history on that website among its many uses.
            </li>
            <li>- Country refers to: Indonesia.</li>
            <li>
              - Device means any device that can access the Service such as a
              computer, a cellphone or a digital tablet.
            </li>
            <li>
              - Personal Data is any information that relates to an identified
              or identifiable individual.
            </li>
            <li>- Service refers to the Website.</li>
            <li>
              - Service Provider means any natural or legal person who processes
              the data on behalf of the Company. It refers to third-party
              companies or individuals employed by the Company to facilitate the
              Service, to provide the Service on behalf of the Company, to
              perform services related to the Service or to assist the Company
              in analyzing how the Service is used. Usage Data refers to data
              collected automatically, either generated by the use of the
              Service or from the Service infrastructure itself (for example,
              the duration of a page visit).
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
