import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import Action from "../redux/Action";
import ButtonSort from "../../../../../components/ButtonSort/ButtonSort";
import { translateEnum } from './translateEnum';

const BorderCustom = () => {
    const customStyle = {
        top: "0px",
        left: "0px",
        width: "5px",
        background: "#144632",
        height: "100%",
        position: "absolute",
    };
    return (<span style={customStyle}></span>);
};

const Table = () => {
    const t = useTranslation();
    const enumStatus = translateEnum(t);
    const dispatch = useDispatch();
    const list = useSelector((state) => state.reducer.list);
    const loading = useSelector((state) => state.reducer.loading);
    const detailTransaction = useSelector((state) => state.reducer.detailTransaction);
    const fieldName = useSelector((state) => state.reducer.fieldName);
    const orderBy = useSelector((state) => state.reducer.orderBy);
    const statusFilter = useSelector((state) => state.reducer.statusFilter);
    const search = useSelector((state) => state.reducer.search);
    const postPerPage = useSelector((state) => state.reducer.postPerPage);
    const fromDate = useSelector((state) => state.reducer.fromDate);
    const toDate = useSelector((state) => state.reducer.toDate);

    const customStyles = {
        transform: "scale(1.02)",
        boxShadow: "0px 15px 23px -8px rgba(0,0,0,0.1)"
    };

    if (loading) {
        return <Skeleton count={7} height="40px" />;
    }

    return (
        <table className="table-auto w-full text-left">
            <thead>
                <tr className="text-green-secondary font-semibold">
                    {/* invoice number */}
                    <th className="p-2 my-2">
                        <ButtonSort
                            title={t("management.invoice_number")}
                            isAsc={
                                fieldName === "noInvoice"
                                    ? orderBy === "ASC"
                                    : false
                            }
                            isDesc={
                                fieldName === "noInvoice"
                                    ? orderBy === "DESC"
                                    : false
                            }
                            sortAsc={() => {
                                Action.getList(dispatch, {
                                    currentPage: 0,
                                    search,
                                    statusFilter,
                                    postPerPage,
                                    fieldName: "noInvoice",
                                    orderBy: "ASC",
                                    fromDate,
                                    toDate
                                });
                            }}
                            sortDesc={() => {
                                Action.getList(dispatch, {
                                    currentPage: 0,
                                    search,
                                    statusFilter,
                                    postPerPage,
                                    fieldName: "noInvoice",
                                    orderBy: "DESC",
                                    fromDate,
                                    toDate
                                });
                            }}
                        />
                    </th>
                    {/* purchase date */}
                    <th>
                        <ButtonSort
                            title={t("management.purchase_date")}
                            isAsc={
                                fieldName === "createdAt"
                                    ? orderBy === "ASC"
                                    : false
                            }
                            isDesc={
                                fieldName === "createdAt"
                                    ? orderBy === "DESC"
                                    : false
                            }
                            sortAsc={() => {
                                Action.getList(dispatch, {
                                    currentPage: 0,
                                    search,
                                    statusFilter,
                                    postPerPage,
                                    fieldName: "createdAt",
                                    orderBy: "ASC",
                                    fromDate,
                                    toDate
                                });
                            }}
                            sortDesc={() => {
                                Action.getList(dispatch, {
                                    currentPage: 0,
                                    search,
                                    statusFilter,
                                    postPerPage,
                                    fieldName: "createdAt",
                                    orderBy: "DESC",
                                    fromDate,
                                    toDate
                                });
                            }}
                        />
                    </th>
                    {/* customer name */}
                    <th>
                        <ButtonSort
                            title={t("management.customer_name")}
                            isAsc={
                                fieldName === "orders.customers.name"
                                    ? orderBy === "ASC"
                                    : false
                            }
                            isDesc={
                                fieldName === "orders.customers.name"
                                    ? orderBy === "DESC"
                                    : false
                            }
                            sortAsc={() => {
                                Action.getList(dispatch, {
                                    currentPage: 0,
                                    search,
                                    statusFilter,
                                    postPerPage,
                                    fieldName: "orders.customers.name",
                                    orderBy: "ASC",
                                    fromDate,
                                    toDate
                                });
                            }}
                            sortDesc={() => {
                                Action.getList(dispatch, {
                                    currentPage: 0,
                                    search,
                                    statusFilter,
                                    postPerPage,
                                    fieldName: "orders.customers.name",
                                    orderBy: "DESC",
                                    fromDate,
                                    toDate
                                });
                            }}
                        />
                    </th>
                    {/* merchantName */}
                    <th>
                        <ButtonSort
                            title={t("management.merchant_name")}
                            isAsc={
                                fieldName === "merchants.name"
                                    ? orderBy === "ASC"
                                    : false
                            }
                            isDesc={
                                fieldName === "merchants.name"
                                    ? orderBy === "DESC"
                                    : false
                            }
                            sortAsc={() => {
                                Action.getList(dispatch, {
                                    currentPage: 0,
                                    search,
                                    statusFilter,
                                    postPerPage,
                                    fieldName: "merchants.name",
                                    orderBy: "ASC",
                                    fromDate,
                                    toDate
                                });
                            }}
                            sortDesc={() => {
                                Action.getList(dispatch, {
                                    currentPage: 0,
                                    search,
                                    statusFilter,
                                    postPerPage,
                                    fieldName: "merchants.name",
                                    orderBy: "DESC",
                                    fromDate,
                                    toDate
                                });
                            }}
                        />
                    </th>
                    {/* status */}
                    <th>
                        <ButtonSort
                            title={t("management.status")}
                            isAsc={
                                fieldName === "status"
                                    ? orderBy === "ASC"
                                    : false
                            }
                            isDesc={
                                fieldName === "status"
                                    ? orderBy === "DESC"
                                    : false
                            }
                            sortAsc={() => {
                                Action.getList(dispatch, {
                                    currentPage: 0,
                                    search: search,
                                    statusFilter: statusFilter,
                                    postPerPage: postPerPage,
                                    fieldName: "status",
                                    orderBy: "ASC",
                                    fromDate,
                                    toDate
                                });
                            }}
                            sortDesc={() => {
                                Action.getList(dispatch, {
                                    currentPage: 0,
                                    search: search,
                                    statusFilter: statusFilter,
                                    postPerPage: postPerPage,
                                    fieldName: "status",
                                    orderBy: "DESC",
                                    fromDate,
                                    toDate
                                });
                            }}
                        />
                    </th>
                </tr>
            </thead>
            <tbody className="font-medium">
                {list.map((item, index) => (
                    <tr
                        key={index}
                        className={`border-4 border-gray-primary bg-white cursor-pointer`}
                        style={detailTransaction.id === item.id ? customStyles : {}}
                        onClick={() => {
                            if (detailTransaction.id !== item.id) {
                                Action.changeShowDetail(dispatch, true, item.id);
                            } else {
                                Action.changeShowDetail(dispatch, false);
                            }
                        }}>
                        <td className="p-2 my-2 relative">
                            {detailTransaction.id === item.id ? <BorderCustom /> : ""}
                            <span className="block ml-2">{item.invoiceNumber}</span>
                        </td>
                        <td className="font-semibold">{item.purchaseDate}</td>
                        <td>{item.customerName}</td>
                        <td>{item.merchantName}</td>
                        <td>{enumStatus[item.status]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default Table;
