import TransactionHistoryDetail from "../pages/management/component/transaction/model/TransactionHistoryDetail";
import TransactionHistoryListModel from "../pages/management/component/transaction/model/TransactionHistoryModel";
import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";
import DateUtility from "../utils/date-utility";
import GeneralUtility from "../utils/general-utility";
import InvoiceModel from "../components/InvoiceModal/model/InvoiceModel";

const BASE_API = process.env.REACT_APP_BASE_API;

const getTransactionHistoryList = async (data) => {
    const url = BASE_API + Endpoint.transactionList;
    const response = await CallApi({ url, method: "POST", data });
    const response1 = typeof response != "undefined" ? response.data : null;
    const response2 = typeof response1 != "undefined" && response1 != null ? response1.data : null;
    const list = typeof response2 != "undefined" && response2 != null ? response2.contents : [];

    const transactionHistory = [];
    for (let i = 0; i < list.length; i++) {
        const element = list[i];
        const customerName = element.customerName || "-";
        const merchantName = element.merchantName || "";
        const purchaseDate = element.purchaseDate || "";
        const invoiceNumber = element.invoiceNumber || "";
        const id = element.orderMerchantId || "";
        const status = element.status || "";

        const item = TransactionHistoryListModel({
            id,
            invoiceNumber,
            purchaseDate: DateUtility.formatDate(purchaseDate, "half"),
            customerName: GeneralUtility.toCapitalize(customerName),
            merchantName: GeneralUtility.toCapitalize(merchantName),
            status
        });
        transactionHistory.push(item);
    }

    let result = {};
    result.error = response?.error || false;
    result.message = response?.message || "";
    result.page = response2?.page || 0;
    result.per_page = response2?.itemPerPage || 0;
    result.total_data = response2?.totalItem || 0;
    result.total_pages = response2?.totalPage || 0;
    result.data = transactionHistory;

    return result;
};

const getTransactionHistoryDetail = async (data) => {
    const url = BASE_API + Endpoint.transactionDetail;
    const response = await CallApi({ url, method: "POST", data });
    const response1 = typeof response != "undefined" ? response.data : null;
    const item = typeof response1 != "undefined" && response1 != null ? response1.data : null;

    const invoiceDetail = InvoiceModel({
        invoiceNumber: item.invoiceNumber || "-",
        purchaseDate: DateUtility.formatDate(item.purchaseDate, "half") || "-",
        address: GeneralUtility.toCapitalize(item.shippingDetail.address) || "-",
        paymentMethod: getPaymentMethod(item) || "-",
        products: formatProductInvoice(item.orderDetail.products) || [],
        totalPrice: getTotalProductPrice(item.orderDetail.products),
        totalShippingCost: GeneralUtility.addCommas(item.orderDetail.totalShippingCost || 0),
        totalShippingInsurance: GeneralUtility.addCommas(item.orderDetail.totalShippingInsurance || 0),
        applicationServiceFee: GeneralUtility.addCommas(item.orderDetail.applicationServiceFee || 0),
        transactionServiceFee: GeneralUtility.addCommas(item.orderDetail.transactionServiceFee || 0),
        totalPricePromo: GeneralUtility.addCommas(item.totalPricePromo || 0),
        totalShippingCostPromo: GeneralUtility.addCommas(item.totalShippingCostPromo || 0),
        totalAdminPromo: GeneralUtility.addCommas(item.totalAdminPromo || 0),
        total: GeneralUtility.addCommas(item.orderDetail.totalPrice || 0)
    });

    const transactionDetail = TransactionHistoryDetail({
        id: item.orderMerchantId || "-",
        invoiceNumber: item.invoiceNumber || "-",
        purchaseDate: DateUtility.formatDate(item.purchaseDate, "half") || "-",
        customerName: item.customerName || "-",
        merchantName: item.merchantName || "-",
        status: item.status.toString() || "-",
        address: GeneralUtility.toCapitalize(item.shippingDetail.address) || "-",
        courier: item.shippingDetail.courier || "-",
        receiptNumber: item.shippingDetail.receiptNumber || "-",
        products: formatProductName(item.orderDetail.products) || "-",
        totalPrice: GeneralUtility.addCommas(item.orderDetail.totalPrice || 0),
        invoice: invoiceDetail
    });

    let result = {};
    result.error = response?.error || false;
    result.message = response?.message || "";
    result.data = transactionDetail;

    return result;
};

function getTotalProductPrice(products) {
    let totalPrice = 0;

    for (const product of products) {
        totalPrice += product.price * product.qty;
    }

    return GeneralUtility.addCommas(totalPrice);
}

const getPaymentMethod = (item) => {
    const { paymentMethod, paymentChannel } = item;
    if (!paymentMethod || !paymentChannel) {
        return "";
    }
    const cleanedPaymentMethod = paymentMethod.replace('_', ' ');
    if (paymentChannel === "CREDIT_CARD") {
        return cleanedPaymentMethod;
    } else {
        const combinedPaymentMethod = `${cleanedPaymentMethod} ${paymentChannel}`;
        return combinedPaymentMethod;
    }
};

const formatProductInvoice = (products) => {
    return products.map(item => ({
        productName: item.name,
        price: GeneralUtility.addCommas(item.price),
        quantity: item.qty,
        notes: item.notes || "-",
        size: item.size || "",
        color: item.color || ""
    }));
};


const formatProductName = (products) => {
    return products.map(item => {
        return {
            name: item.name,
            price: `${item.qty}xRp${GeneralUtility.addCommas(item.price)}`
        };
    });
};
const ManagementTransactionRepository = {
    getTransactionHistoryList,
    getTransactionHistoryDetail
};

export default ManagementTransactionRepository;