import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { translateEnum } from "./translateEnum";
import React from "react";
import { setShowInvoiceModal } from "../redux/Reducer";
import InvoiceModal from "../../../../../components/InvoiceModal/InvoiceModal";

const Detail = () => {
    const t = useTranslation();
    const enumStatus = translateEnum(t);
    const dispatch = useDispatch();
    const detailTransaction = useSelector(state => state.reducer.detailTransaction);
    const showInvoiceModal = useSelector(state => state.reducer.showInvoiceModal);
    const detailInvoice = useSelector(state => state.reducer.detailInvoice);

    return (
        <>
            <div className="font-medium text-sm">
                <div className="flex flex-row items-center pb-4">
                    <h4 className="flex-1 opacity-60 font-bold">
                        DETAILS
                    </h4>
                </div>

                <div className="h-72 overflow-y-auto">
                    <div className="flex flex-row items-right pb-2">
                        <div className="flex-1">
                            <p className="text-green-quaternary">
                                {t("management.purchase_date")}
                            </p>
                            <p className="font-bold text-md">{detailTransaction.purchaseDate}</p>
                        </div>
                        <div className="text-right">
                            <div className="min-w-fit py-1 px-4 text-center text-green-primary font-bold rounded-xl border-2 border-green-secondary border-opacity-40">
                                {enumStatus[detailTransaction.status]}
                            </div>
                        </div>
                    </div>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("management.invoice_number")}
                        </p>
                        <p
                            onClick={() => {
                                dispatch(setShowInvoiceModal(true));
                            }}
                            className="font-bold text-md underline text-gold-secondary cursor-pointer">
                            {detailTransaction.invoiceNumber}
                        </p>
                    </div>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("management.customer_name")}
                        </p>
                        <p className="font-bold text-md">
                            {detailTransaction.customerName}
                        </p>
                    </div>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("management.merchant_name")}
                        </p>
                        <p className="font-bold text-md">
                            {detailTransaction.merchantName}
                        </p>
                    </div>

                    <hr />

                    <h4 className="flex-1 text-green-primary font-bold my-2 text-sm">
                        {t("management.shipping_detail").toUpperCase()}
                    </h4>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("management.address")}
                        </p>
                        <p className="font-bold text-md">
                            {detailTransaction.address}
                        </p>
                    </div>

                    <div className="flex items-start justify-between pr-2">
                        <div className="pb-2">
                            <p className="text-green-quaternary">
                                {t("management.courier")}
                            </p>
                            <p className="font-bold text-md">
                                {detailTransaction.courier}
                            </p>
                        </div>

                        <div className="pb-2">
                            <p className="text-green-quaternary">
                                {t("management.receipt_number")}
                            </p>
                            <p className="font-bold text-md">
                                {detailTransaction.receiptNumber}
                            </p>
                        </div>
                    </div>

                    <hr />

                    <h4 className="flex-1 text-green-primary font-bold my-2 text-sm">
                        {t("management.order_details").toUpperCase()}
                    </h4>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("management.products")}
                        </p>
                        {detailTransaction.products.map((item, i) => (
                            <React.Fragment key={i}>
                                <p className="font-bold text-md mb-1" key={i}>
                                    {item.name}
                                </p>
                                <p className="font-bold text-sm mb-2 text-green-secondary" key={i}>
                                    {item.price}
                                </p>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="pb-2">
                        <p className="text-green-quaternary">
                            {t("management.total_price")}
                        </p>
                        <p className="font-bold text-md mb-2">
                            Rp{detailTransaction.totalPrice}
                        </p>
                    </div>
                </div>
            </div>

            {showInvoiceModal ? (
                <InvoiceModal
                    title={detailInvoice.invoiceNumber}
                    invoiceDetail={detailInvoice}
                    onCancel={() => {
                        dispatch(setShowInvoiceModal(false));
                    }} />
            ) : ""}
        </>
    );
};

export default Detail;
