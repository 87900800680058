import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
    name: "ManagementPromo",
    initialState: {
        list: [],
        showDetail: false,
        loading: true,
        postPerPage: 5,
        fieldName: "",
        orderBy: "",
        search: "",
        currentPage: 0,
        totalData: 0,
        totalPage: 0,
        detailPromo: {},
        disabledButton: false,
        typeDisableButton: ""
    },
    reducers: {
        setShowDetail: (state, action) => {
            state.showDetail = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setPostPerPage: (state, action) => {
            state.postPerPage = action.payload;
        },
        setFieldName: (state, action) => {
            state.fieldName = action.payload;
        },
        setOrderBy: (state, action) => {
            state.orderBy = action.payload;
        },
        setSearch: (state, action) => {
            state.search = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTotalData: (state, action) => {
            state.totalData = action.payload;
        },
        setTotalPage: (state, action) => {
            state.totalPage = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        setDetailPromo: (state, action) => {
            state.detailPromo = action.payload;
        },
        setDisabledButton: (state, action) => {
            state.disabledButton = action.payload;
        },
        setTypeDisableButton: (state, action) => {
            state.typeDisableButton = action.payload;
        }
    }
});

export const {
    setShowDetail,
    setPostPerPage,
    setFieldName,
    setOrderBy,
    setSearch,
    setCurrentPage,
    setTotalData,
    setTotalPage,
    setList,
    setLoading,
    setDetailPromo,
    setDisabledButton,
    setTypeDisableButton
} = reducerSlice.actions;

export default reducerSlice;