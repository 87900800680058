import ManagementPromoRepository from "../../../../../repositories/ManagementPromoRepository";
import PopupUtility from "../../../../../utils/popup-utility";
import { setCurrentPage, setDeleteModal, setDetailPromo, setFieldName, setList, setLoading, setOrderBy, setPostPerPage, setSearch, setShowDetail, setStatusFilter, setTotalData, setTotalPage } from "./Reducer";

const getList = async (
    dispatch,
    { currentPage, search, statusFilter, postPerPage, fieldName, orderBy }
) => {

    dispatch(setLoading(true));
    dispatch(setCurrentPage(currentPage));
    dispatch(setSearch(search));
    dispatch(setStatusFilter(statusFilter));
    dispatch(setPostPerPage(postPerPage));
    dispatch(setFieldName(fieldName));
    dispatch(setOrderBy(orderBy));

    const { error, data, total_data, total_pages } = await ManagementPromoRepository.getList({
        currentPage,
        search,
        statusFilter,
        postPerPage,
        fieldName,
        orderBy
    });

    if (!error) {
        dispatch(setList(data));
        dispatch(setLoading(false));
        dispatch(setTotalData(total_data));
        dispatch(setTotalPage(total_pages));
    }
};

const changeShowDetail = (dispatch, boolean, item) => {
    if (item) {
        dispatch(setDetailPromo(item));
    } else {
        dispatch(setDetailPromo({}));
    }
    dispatch(setShowDetail(boolean));
};

const changeModalDelete = (dispatch, boolean) => {
    dispatch(setDeleteModal(boolean));
};

const handleSubmitDelete = async (dispatch, { id, remark }) => {
    const payload = { remarks: remark };
    const response = await ManagementPromoRepository.deletePromo(id, payload);

    if (!response.error) {
        dispatch(setDeleteModal(false));
        dispatch(setShowDetail(false));
        getList(dispatch, {
            search: "",
            currentPage: 0,
            postPerPage: 5,
            statusFilter: "",
            fieldName: "",
            orderBy: ""
        });
        return PopupUtility.success("Successfully delete promo!");
    }
    const message = `
        <p class="text-lg mb-3 font-extrabold">${response.message}</p>
        <p class="text-sm font-bold text-gray-400 tracking-wider">Trace Id : ${response.trace_id}</p>`;
    PopupUtility.responseValidate("Error", message);
};

const Action = {
    getList,
    changeShowDetail,
    changeModalDelete,
    handleSubmitDelete
};

export default Action;