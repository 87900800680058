import React from 'react';
import { useTranslation } from 'react-multi-lang';
import Action from '../redux/Action';
import { useDispatch, useSelector } from 'react-redux';

const HeaderTableCustomer = () => {
    const t = useTranslation();
    const dispatch = useDispatch();

    const postPerPageCustomer = useSelector(state => state.reducer.postPerPageCustomer);
    const searchCustomer = useSelector(state => state.reducer.searchCustomer);

    let filterTimeout = null;
    return (
        <div className='grid grid-cols-3'>
            <div className="col-span-2">
                <label className="relative block">
                    <span className="absolute inset-y-0 left-1.5 flex items-center pl-2">
                        <img
                            className="h-3.5 w-5 fill-slate-300"
                            src={process.env.REACT_APP_ASSETS_IMAGE + "username-icon.svg"}
                            alt="username-icon"
                        />
                    </span>
                    <input
                        type="text"
                        placeholder={t("management.search_promo")}
                        className="pl-10 pr-3 w-full block sm:text-sm shadow-sm border border-gray-primary rounded-xl bg-white  placeholder:text-slate-40 "
                        onChange={(e) => {
                            clearTimeout(filterTimeout);
                            filterTimeout = setTimeout(() => {
                                Action.getListCustomers(dispatch, {
                                    page: 0,
                                    search: e.target.value,
                                    size: postPerPageCustomer,
                                    status: "1"
                                });
                            }, 800);
                        }}
                    />
                </label>
            </div>
            <div className="col-span-1 justify-self-end">
                <label htmlFor="postperview" className="pr-2 text-sm font-medium">
                    {t("management.view")}
                </label>
                <select
                    className="sm:text-sm shadow-sm border border-gray-primary rounded-xl"
                    name="postperview"
                    id="postperview"
                    onChange={(e) => {
                        Action.getListCustomers(dispatch, {
                            page: 0,
                            search: searchCustomer,
                            size: parseInt(e.target.value),
                            status: "1"
                        });
                    }}>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                </select>
            </div>
        </div>
    );
};

export default HeaderTableCustomer;