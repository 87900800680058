import Cookies from "js-cookie";
import { setBreadcrums, setTranslation } from "./Reducer";
import AuthRepository from "../../../repositories/AuthRepository";

const getBreadcrums = async (dispatch, title, link) => {
  let breadcrums = [];
  let data = {};
  data.title = title;
  data.link = link;
  breadcrums.push(data);
  dispatch(setBreadcrums(breadcrums));
};

const changeTranslation = async (dispatch, lang) => {
  dispatch(setTranslation(lang));
  Cookies.set("Language", lang);
};

const getStatusMaintenance = async () => {
  const response = await AuthRepository.getStatusMaintentance();
  return response;
};

const Action = {
  getBreadcrums,
  changeTranslation,
  getStatusMaintenance
};

export default Action;
