function TransactionHistoryDetail({
    id,
    invoiceNumber,
    purchaseDate,
    customerName,
    merchantName,
    status,
    address,
    courier,
    receiptNumber,
    products,
    totalPrice,
    invoice
}) {
    let obj = {};
    obj.id = id;
    obj.purchaseDate = purchaseDate;
    obj.invoiceNumber = invoiceNumber;
    obj.customerName = customerName;
    obj.merchantName = merchantName;
    obj.status = status;
    obj.address = address;
    obj.courier = courier;
    obj.receiptNumber = receiptNumber;
    obj.products = products;
    obj.totalPrice = totalPrice;
    obj.invoice = invoice;
    return obj;
}

export default TransactionHistoryDetail;