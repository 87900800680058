import React, { useState } from 'react';
import ModalTableCustomer from './ModalTableCustomer';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPageCustomer, setListCustomer, setPostPerPageCustomer, setSearchCustomer, setSelectedCustomers, setTotalDataCustomer, setTotalPageCustomer } from '../redux/Reducer';

const SelectCustomer = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const selectedCustomers = useSelector((state) => state.reducer.selectedCustomers);
    const formMode = useSelector((state) => state.reducer.formMode);

    const handleCloseModal = () => {
        dispatch(setListCustomer([]));
        dispatch(setTotalPageCustomer(0));
        dispatch(setTotalDataCustomer(0));
        dispatch(setCurrentPageCustomer(0));
        dispatch(setPostPerPageCustomer(5));
        dispatch(setSearchCustomer(""));
        setOpen(false);
    };

    const handleRemoveCustomer = (customer) => {
        if (formMode === "EDIT") {
            const updatedCustomer = { ...customer, action: "REMOVE" };
            dispatch(setSelectedCustomers([...selectedCustomers.filter(c => c.id !== customer.id), updatedCustomer]));
        } else {
            dispatch(setSelectedCustomers(selectedCustomers.filter(c => c.id !== customer.id)));
        }
    };

    return (
        <>
            <div className='bg-white rounded-xl py-2.5 pl-2.5 pr-16 text-justify relative min-h-[40px] flex flex-wrap gap-2'>
                <div
                    role='button'
                    onClick={() => setOpen(true)}
                    className='bg-green-primary px-2.5 py-1 rounded-lg text-white text-sm absolute top-2.5 right-2.5'>Add</div>
                {selectedCustomers.filter(customer => customer.action !== "REMOVE").map((customer) => (
                    <label key={customer.id} className='px-2.5 py-1 bg-gray-200 rounded-lg gap-2 flex items-center w-fit'>
                        <span className='capitalize'>{customer.customerName}</span>
                        <span
                            className="font-bold"
                            role='button'
                            onClick={() => handleRemoveCustomer(customer)}>
                            x
                        </span>
                    </label>
                ))}
            </div>

            {open && (
                <ModalTableCustomer onCancel={handleCloseModal} onClose={() => setOpen(false)} />
            )}
        </>
    );
};

export default SelectCustomer;
