import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
    name: "TransactionHistory",
    initialState: {
        list: [],
        showDetail: false,
        totalData: 0,
        totalPage: 0,
        loading: true,
        currentPage: 0,
        search: "",
        statusFilter: "",
        postPerPage: 5,
        fieldName: "",
        orderBy: "",
        fromDate: "",
        toDate: "",
        detailTransaction: {},
        detailInvoice: {},
        showInvoiceModal: false
    },
    reducers: {
        setList: (state, action) => {
            state.list = action.payload;
        },
        setShowDetail: (state, action) => {
            state.showDetail = action.payload;
        },
        setTotalData: (state, action) => {
            state.totalData = action.payload;
        },
        setTotalPage: (state, action) => {
            state.totalPage = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setSearch: (state, action) => {
            state.search = action.payload;
        },
        setStatusFilter: (state, action) => {
            state.statusFilter = action.payload;
        },
        setPostPerPage: (state, action) => {
            state.postPerPage = action.payload;
        },
        setFieldName: (state, action) => {
            state.fieldName = action.payload;
        },
        setOrderBy: (state, action) => {
            state.orderBy = action.payload;
        },
        setFromDate: (state, action) => {
            state.fromDate = action.payload;
        },
        setToDate: (state, action) => {
            state.toDate = action.payload;
        },
        setDetailTransaction: (state, action) => {
            state.detailTransaction = action.payload;
        },
        setDetailInvoice: (state, action) => {
            state.detailInvoice = action.payload;
        },
        setShowInvoiceModal: (state, action) => {
            state.showInvoiceModal = action.payload;
        }
    }
});

export const {
    setList,
    setShowDetail,
    setTotalData,
    setTotalPage,
    setLoading,
    setCurrentPage,
    setSearch,
    setStatusFilter,
    setPostPerPage,
    setFieldName,
    setOrderBy,
    setFromDate,
    setToDate,
    setDetailTransaction,
    setDetailInvoice,
    setShowInvoiceModal
} = reducerSlice.actions;

export default reducerSlice;