import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const TableCustomer = ({ onSelectionChange, resetSelection }) => {
    const listCustomer = useSelector((state) => state.reducer.listCustomer);
    const [selectedCustomers, setSelectedCustomers] = useState([]);

    useEffect(() => {
        onSelectionChange(selectedCustomers);
    }, [selectedCustomers, onSelectionChange]);

    useEffect(() => {
        if (resetSelection) {
            setSelectedCustomers([]);
        }
    }, [resetSelection]);

    const handleCheckboxChange = (customer) => {
        setSelectedCustomers((prevSelectedCustomers) => {
            if (prevSelectedCustomers.includes(customer)) {
                return prevSelectedCustomers.filter((item) => item !== customer);
            } else {
                return [...prevSelectedCustomers, customer];
            }
        });
    };

    const handleSelectAllChange = (e) => {
        if (e.target.checked) {
            setSelectedCustomers(listCustomer);
        } else {
            setSelectedCustomers([]);
        }
    };

    return (
        <table className="table-auto w-full text-left my-5">
            <thead>
                <tr className="text-green-secondary font-semibold">
                    <th className="p-2 my-2">
                        {/* <input
                            type="checkbox"
                            className="form-checkbox h-5 w-5 text-green-secondary border-gray-300 rounded"
                            onChange={handleSelectAllChange}
                            checked={selectedCustomers.length === listCustomer.length && listCustomer.length > 0}
                        /> */}
                    </th>
                    <th>
                        <p>Customer Name</p>
                    </th>
                    <th>
                        <p>Email</p>
                    </th>
                </tr>
            </thead>
            <tbody className="font-medium">
                {listCustomer.map((item, index) => (
                    <tr
                        className={`border-4 border-gray-primary bg-white cursor-pointer`}
                        key={index}
                    >
                        <td className="p-2">
                            <input
                                type="checkbox"
                                className="form-checkbox h-5 w-5 text-green-secondary border-gray-300 rounded"
                                onChange={() => handleCheckboxChange(item)}
                                checked={selectedCustomers.includes(item)}
                            />
                        </td>
                        <td>{item.customerName}</td>
                        <td>{item.customerEmail}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TableCustomer;
