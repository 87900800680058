function InvoiceModel({
    invoiceNumber,
    purchaseDate,
    address,
    paymentMethod,
    products,
    totalPrice,
    totalShippingCost,
    totalShippingInsurance,
    applicationServiceFee,
    transactionServiceFee,
    totalPricePromo,
    totalShippingCostPromo,
    total, 
    totalAdminPromo
}) {
    let obj = {};
    obj.invoiceNumber = invoiceNumber;
    obj.purchaseDate = purchaseDate;
    obj.address = address;
    obj.paymentMethod = paymentMethod;
    obj.products = products;
    obj.totalPrice = totalPrice;
    obj.totalShippingCost = totalShippingCost;
    obj.totalShippingInsurance = totalShippingInsurance;
    obj.applicationServiceFee = applicationServiceFee;
    obj.transactionServiceFee = transactionServiceFee;
    obj.totalPricePromo = totalPricePromo;
    obj.totalShippingCostPromo = totalShippingCostPromo;
    obj.total = total;
    obj.totalAdminPromo = totalAdminPromo;
    return obj;
}

export default InvoiceModel;