import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import ButtonSort from "../../../../../components/ButtonSort/ButtonSort";
import Action from "../redux/Action";
import Enum from "./Enum";

const Tabel = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.reducer.list);
  const detailCustomer = useSelector((state) => state.reducer.detailCustomer);
  const loading = useSelector((state) => state.reducer.loading);
  const showDetail = useSelector((state) => state.reducer.showDetail);
  const postPerPage = useSelector((state) => state.reducer.postPerPage);
  const search = useSelector((state) => state.reducer.search);
  const statusFilter = useSelector((state) => state.reducer.statusFilter);
  const fieldName = useSelector((state) => state.reducer.fieldName);
  const orderBy = useSelector((state) => state.reducer.orderBy);

  if (loading) {
    return <Skeleton count={7} height="40px" />;
  }

  return (
    <table className="table-auto w-full text-left">
      <thead>
        <tr className="text-green-secondary font-semibold">
          {/* issue date */}
          <th className="p-2 my-2">
            <ButtonSort
              title={t("helpcenter.issued_date")}
              isAsc={
                fieldName === "issuedDate"
                  ? orderBy === "asc"
                  : false
              }
              isDesc={
                fieldName === "issuedDate"
                  ? orderBy === "desc"
                  : false
              }
              sortAsc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  postPerPage,
                  statusFilter,
                  fieldName: "issuedDate",
                  orderBy: "asc",
                });
              }}
              sortDesc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  postPerPage,
                  statusFilter,
                  fieldName: "issuedDate",
                  orderBy: "desc",
                });
              }}
            />
          </th>
          {/* feedback id */}
          <th>
            <ButtonSort
              title={t("helpcenter.feedback_id")}
              isAsc={
                fieldName === "feedbackId"
                  ? orderBy === "asc"
                  : false
              }
              isDesc={
                fieldName === "feedbackId"
                  ? orderBy === "desc"
                  : false
              }
              sortAsc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  postPerPage,
                  statusFilter,
                  fieldName: "feedbackId",
                  orderBy: "asc",
                });
              }}
              sortDesc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  postPerPage,
                  statusFilter,
                  fieldName: "feedbackId",
                  orderBy: "desc",
                });
              }}
            />
          </th>
          {/* invoice id */}
          <th>
            <ButtonSort
              title={t("helpcenter.invoice_id")}
              isAsc={
                fieldName === "noInvoice"
                  ? orderBy === "asc"
                  : false
              }
              isDesc={
                fieldName === "noInvoice"
                  ? orderBy === "desc"
                  : false
              }
              sortAsc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  postPerPage,
                  statusFilter,
                  fieldName: "noInvoice",
                  orderBy: "asc",
                });
              }}
              sortDesc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  postPerPage,
                  statusFilter,
                  fieldName: "noInvoice",
                  orderBy: "desc",
                });
              }}
            />
          </th>
          {/* customer name */}
          <th>
            <ButtonSort
              title={t("helpcenter.customer_name")}
              isAsc={
                fieldName === "customerName"
                  ? orderBy === "asc"
                  : false
              }
              isDesc={
                fieldName === "customerName"
                  ? orderBy === "desc"
                  : false
              }
              sortAsc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  postPerPage,
                  statusFilter,
                  fieldName: "customerName",
                  orderBy: "asc",
                });
              }}
              sortDesc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  postPerPage,
                  statusFilter,
                  fieldName: "customerName",
                  orderBy: "desc",
                });
              }}
            />
          </th>
          {/* category */}
          <th>
            <ButtonSort
              title={t("helpcenter.category")}
              isAsc={
                fieldName === "category"
                  ? orderBy === "asc"
                  : false
              }
              isDesc={
                fieldName === "category"
                  ? orderBy === "desc"
                  : false
              }
              sortAsc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  postPerPage,
                  statusFilter,
                  fieldName: "category",
                  orderBy: "asc",
                });
              }}
              sortDesc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  postPerPage,
                  statusFilter,
                  fieldName: "category",
                  orderBy: "desc",
                });
              }}
            />
          </th>
          {/* status */}
          <th>
            <ButtonSort
              title={t("helpcenter.status")}
              isAsc={
                fieldName === "status"
                  ? orderBy === "asc"
                  : false
              }
              isDesc={
                fieldName === "status"
                  ? orderBy === "desc"
                  : false
              }
              sortAsc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  postPerPage,
                  statusFilter,
                  fieldName: "status",
                  orderBy: "asc",
                });
              }}
              sortDesc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  postPerPage,
                  statusFilter,
                  fieldName: "status",
                  orderBy: "desc",
                });
              }}
            />
          </th>
        </tr>
      </thead>
      <tbody className="font-medium">
        {list.map((item, index) => (
          <tr
            key={index}
            className={`${detailCustomer.id !== item.id ? "" : "border-l-green-primary"}
              border-4 border-gray-primary bg-white cursor-pointer`}
            onClick={() => {
              if (detailCustomer.id !== item.id) {
                Action.changeShowDetail(dispatch, true, item.id);
              } else {
                Action.changeShowDetail(dispatch, false);
              }
            }}
          >
            <td className="p-2 my-2">{item.issuedDate}</td>
            <td className="font-semibold">{item.feedbackId}</td>
            <td className="font-semibold">{item.noInvoice}</td>
            <td>{item.customerName}</td>
            <td>{item.category}</td>
            <td>{Enum(t, [item.status])}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Tabel;
