import moment from "moment";
import ManagementTransactionRepository from "../../../../../repositories/ManagementTransactionRepository";
import PopupUtility from "../../../../../utils/popup-utility";
import { setCurrentPage, setDetailInvoice, setDetailTransaction, setFieldName, setFromDate, setList, setLoading, setOrderBy, setPostPerPage, setSearch, setShowDetail, setStatusFilter, setToDate, setTotalData, setTotalPage } from "./Reducer";

const getList = async (
    dispatch,
    { currentPage, search, statusFilter, postPerPage, fieldName, orderBy, toDate, fromDate }
) => {
    dispatch(setLoading(true));
    dispatch(setCurrentPage(currentPage));
    dispatch(setSearch(search));
    dispatch(setStatusFilter(statusFilter));
    dispatch(setPostPerPage(postPerPage));
    dispatch(setFieldName(fieldName));
    dispatch(setOrderBy(orderBy));
    dispatch(setFromDate(fromDate));
    dispatch(setToDate(toDate));

    const data = {
        fromDate: formateDate(fromDate) || null,
        toDate: formateDate(toDate) || null,
        status: formatStatus(statusFilter) || null,
        page: currentPage,
        itemPerPage: postPerPage || 5,

        search: search || null,
        sortDirection: orderBy || null,
        sortBy: fieldName || null
    };

    let response = await ManagementTransactionRepository.getTransactionHistoryList(data);

    if (response.error === false) {
        dispatch(setLoading(false));
        dispatch(setList(response.data));
        dispatch(setTotalData(response.total_data));
        dispatch(setTotalPage(response.total_pages));
    } else {
        dispatch(setLoading(true));
        dispatch(setList([]));
        dispatch(setTotalData(0));
        dispatch(setTotalPage(0));
    }
};

const formatStatus = (value) => {
    const status = value.toString();
    switch (status) {
        case "-2":
            return "COMPLAINED";
        case "-1":
            return "CANCELED";
        case "1":
            return "PAID";
        case "2":
            return "ON_DELIVERY";
        case "3":
            return "RECEIVED";
        case "4":
            return "COMPLETED";
        case "11":
            return "READY_TO_SELF_PICKUP";
        default:
            return "";
    }
};

const formateDate = (date) => {
    if (date === "") {
        return "";
    }
    return moment(date).format("YYYY-MM-DD");
};

const changeShowDetail = async (dispatch, showDetail, id) => {
    if (id) {
        let response = await ManagementTransactionRepository.getTransactionHistoryDetail({ orderMerchantId: id });
        if (response.error === false) {
            dispatch(setDetailTransaction(response.data));
            dispatch(setDetailInvoice(response.data.invoice));
        } else {
            PopupUtility.responseValidate("Failed", response.message);
            dispatch(setDetailTransaction({}));
            dispatch(setDetailInvoice({}));
        }
    } else {
        dispatch(setDetailTransaction({}));
        dispatch(setDetailInvoice({}));
    }
    dispatch(setShowDetail(showDetail));
};

const Action = {
    getList,
    changeShowDetail
};

export default Action;
