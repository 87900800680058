import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-multi-lang';
import { useSelector } from 'react-redux';

const TotalPromo = () => {
    const t = useTranslation();

    const loading = useSelector((state) => state.reducer.loading);
    const totalData = useSelector((state) => state.reducer.totalData);

    if (loading) {
        return <Skeleton count={2} height="20px" />;
    }

    return (
        <>
            <h4 className="opacity-60 font-bold pb-5">
                {t("management.details").toUpperCase()}
            </h4>
            <p className="text-sm font-medium">{t("management.total_promo")}</p>
            <p className="text-md font-bold">{totalData}</p>
        </>
    );
};

export default TotalPromo;