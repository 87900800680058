import AuthRepository from "../../../../repositories/AuthRepository";
import EncryptDecrypt from "../../../../services/encrypt-decrypt";
import { setOtp, setErrorOtp, setQrCode, setSpinner } from "./Reducer";
import Cookies from "js-cookie";
import UseAuth from "../../Auth";
import RouteName from "../../../../services/routename";
import { t } from "react-multi-lang";

const generateQR = async (dispatch) => {
  const data = {
    email: null
  }
  const response = await AuthRepository.generateQR(data);
  const qrCode = response.data.qr;
  const secretKey = response.data.secretKey;
  Cookies.set("secretKey", EncryptDecrypt.enc(secretKey), { expires: 3 });
  dispatch(setQrCode(qrCode));
};

const validate2faCode = async (dispatch, otp) => {
  dispatch(setSpinner(true))
  const key = Cookies.get("secretKey");
  const keyData = EncryptDecrypt.dec(key);
  const otpString = otp.join("");
  const user = UseAuth.getUser();
  const data = {
    secretKey: keyData,
    code: otpString,
  };
  const response = await AuthRepository.validate2faCode(data);
  if (!response.error) {
    let redirectTo;
      switch (user.roleId) {
        case "SA1":
        case "MG1":
          redirectTo = RouteName.dashboard;
          break;
        case "MG2":
        case "MG3":
        case "ST1":
        case "ST2":
          redirectTo = RouteName.management;
          break;
        case "ST4":
          redirectTo = RouteName.generateqr;
          break;
        case "ST3":
          redirectTo = RouteName.balance;
          break;
        default:
          break;
      }

      if (redirectTo) {
        window.location = redirectTo;
      }
  } else {
    dispatch(setErrorOtp(t("login.errorotp")))
    dispatch(setSpinner(false))
  }
};

const Action = {
  generateQR,
  validate2faCode,
};

export default Action;
