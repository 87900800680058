// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-notif {
  background-color: #bbab7f;
  border-radius: 5px;
}

.header-multilang select {
  outline: none;
  border: 1px solid #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
  cursor: pointer;
}

.header-multilang select:focus,
select:hover {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.329);
}

.header-profil {
  background-color: #5d7d73;
  box-shadow: 6px 8px 15px #2b615240;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/header/Header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,4CAA4C;EAC5C,eAAe;AACjB;;AAEA;;EAEE,aAAa;EACb,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,kCAAkC;EAClC,mBAAmB;AACrB","sourcesContent":[".header-notif {\n  background-color: #bbab7f;\n  border-radius: 5px;\n}\n\n.header-multilang select {\n  outline: none;\n  border: 1px solid #fff;\n  border-radius: 0.5rem;\n  box-shadow: 0 1px 4px rgb(146 161 176 / 15%);\n  cursor: pointer;\n}\n\n.header-multilang select:focus,\nselect:hover {\n  outline: none;\n  border: 1px solid rgba(0, 0, 0, 0.329);\n}\n\n.header-profil {\n  background-color: #5d7d73;\n  box-shadow: 6px 8px 15px #2b615240;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
