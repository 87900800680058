function NotificationModel({
  id,
  from,
  title,
  subtitle,
  createdAt,
  read,
  type,
  subType,
  dataId,
}) {
  return {
    id,
    from,
    title,
    subtitle,
    createdAt,
    read,
    type,
    subType,
    dataId,
  };
}

export default NotificationModel;