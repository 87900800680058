function CustomerModel({
  id,
  issuedDate,
  feedbackId,
  category,
  topic,
  status,
  statusName,
  noInvoice,
  customerName
}) {
  let obj = {};
  obj.id = id;
  obj.issuedDate = issuedDate;
  obj.feedbackId = feedbackId;
  obj.category = category;
  obj.topic = topic;
  obj.status = status;
  obj.statusName = statusName;
  obj.noInvoice = noInvoice;
  obj.customerName = customerName;
  return obj;
}

export default CustomerModel;
