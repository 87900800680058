import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import Action from "../redux/Action";
import ButtonSort from "../../../../../components/ButtonSort/ButtonSort";

const BorderCustom = () => {
  const customStyle = {
    top: "0px",
    left: "0px",
    width: "5px",
    background: "#144632",
    height: "100%",
    position: "absolute",
  };
  return (<span style={customStyle}></span>);
};

const Table = () => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const list = useSelector((state) => state.reducer.list);
  const loading = useSelector((state) => state.reducer.loading);
  const detailPromo = useSelector((state) => state.reducer.detailPromo);
  const fieldName = useSelector((state) => state.reducer.fieldName);
  const orderBy = useSelector((state) => state.reducer.orderBy);
  const statusFilter = useSelector((state) => state.reducer.statusFilter);
  const search = useSelector((state) => state.reducer.search);
  const postPerPage = useSelector((state) => state.reducer.postPerPage);

  const customStyles = {
    transform: "scale(1.02)",
    boxShadow: "0px 15px 23px -8px rgba(0,0,0,0.1)"
  };

  if (loading) {
    return <Skeleton count={7} height="40px" />;
  }

  return (
    <table className="table-auto w-full text-left">
      <thead>
        <tr className="text-green-secondary font-semibold">
          {/* promo id */}
          <th className="p-2 my-2">
            <ButtonSort
              title={t("management.promo_id")}
              isAsc={
                fieldName === "promoId"
                  ? orderBy === "ASC"
                  : false
              }
              isDesc={
                fieldName === "promoId"
                  ? orderBy === "DESC"
                  : false
              }
              sortAsc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  statusFilter,
                  postPerPage,
                  fieldName: "promoId",
                  orderBy: "ASC"
                });
              }}
              sortDesc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  statusFilter,
                  postPerPage,
                  fieldName: "promoId",
                  orderBy: "DESC"
                });
              }}
            />
          </th>
          {/* promo name */}
          <th>
            <ButtonSort
              title={t("management.promo_name")}
              isAsc={
                fieldName === "name"
                  ? orderBy === "ASC"
                  : false
              }
              isDesc={
                fieldName === "name"
                  ? orderBy === "DESC"
                  : false
              }
              sortAsc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  statusFilter,
                  postPerPage,
                  fieldName: "name",
                  orderBy: "ASC"
                });
              }}
              sortDesc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  statusFilter,
                  postPerPage,
                  fieldName: "name",
                  orderBy: "DESC"
                });
              }}
            />
          </th>
          {/* promo code */}
          <th>
            <ButtonSort
              title={t("management.promo_code")}
              isAsc={
                fieldName === "code"
                  ? orderBy === "ASC"
                  : false
              }
              isDesc={
                fieldName === "code"
                  ? orderBy === "DESC"
                  : false
              }
              sortAsc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  statusFilter,
                  postPerPage,
                  fieldName: "code",
                  orderBy: "ASC"
                });
              }}
              sortDesc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  statusFilter,
                  postPerPage,
                  fieldName: "code",
                  orderBy: "DESC"
                });
              }}
            />
          </th>
          {/* promo date */}
          <th>
            <p>{t("management.promo_date")}</p>
            {/* <ButtonSort
              title={t("management.promo_date")}
              isAsc={
                fieldName === "promoDate"
                  ? orderBy === "ASC"
                  : false
              }
              isDesc={
                fieldName === "promoDate"
                  ? orderBy === "DESC"
                  : false
              }
              sortAsc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  statusFilter,
                  postPerPage,
                  fieldName: "promoDate",
                  orderBy: "ASC"
                });
              }}
              sortDesc={() => {
                Action.getList(dispatch, {
                  currentPage: 0,
                  search,
                  statusFilter,
                  postPerPage,
                  fieldName: "promoDate",
                  orderBy: "DESC"
                });
              }}
            /> */}
          </th>
          {/* status */}
          <th>
            <p>{t("management.status")}</p>
          </th>
        </tr>
      </thead>
      <tbody className="font-medium">
        {list.map((item, index) => (
          <tr
            key={index}
            className={`border-4 border-gray-primary bg-white cursor-pointer`}
            style={detailPromo.id === item.id ? customStyles : {}}
            onClick={() => {
              if (detailPromo.id !== item.id) {
                Action.changeShowDetail(dispatch, true, item);
              } else {
                Action.changeShowDetail(dispatch, false);
              }
            }}>
            <td className="p-2 my-2 relative">
              {detailPromo?.id === item.id ? <BorderCustom /> : ""}
              <span className="block ml-2">{item.promoId}</span>
            </td>
            <td className="font-semibold">{item.promoName}</td>
            <td>{item.promoCode}</td>
            <td>{item.date}</td>
            <td>{item.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
