import React, { useEffect, useState } from 'react';
import HeaderTableCustomer from "./HeaderTableCustomer";
import TableCustomer from './TableCustomer';
import Pagination from '../../../../../../../components/Pagination/Pagination';
import Action from '../redux/Action';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCustomers } from '../redux/Reducer';

const ModalTableCustomer = ({ onClose, onCancel }) => {
    const dispatch = useDispatch();

    const [selectedCustomers, setSelectedArrayCustomers] = useState([]);
    const [selectedLabelCustomers, setSelectedLabelCustomers] = useState([]);
    const [resetSelection, setResetSelection] = useState(false);

    const previousSelectedCustomers = useSelector(state => state.reducer.selectedCustomers);
    const postPerPageCustomer = useSelector(state => state.reducer.postPerPageCustomer);
    const searchCustomer = useSelector(state => state.reducer.searchCustomer);
    const totalPageCustomer = useSelector(state => state.reducer.totalPageCustomer);
    const totalDataCustomer = useSelector(state => state.reducer.totalDataCustomer);
    const currentPageCustomer = useSelector(state => state.reducer.currentPageCustomer);
    const formMode = useSelector(state => state.reducer.formMode);

    useEffect(() => {
        Action.getListCustomers(dispatch, {
            size: postPerPageCustomer,
            search: searchCustomer,
            page: 0,
            status: "1"
        });
    }, [dispatch, postPerPageCustomer, searchCustomer]);

    const handleSelect = () => {
        // Gabungkan selectedCustomers dengan selectedLabelCustomers tanpa duplikat
        const combinedCustomers = [
            ...selectedLabelCustomers,
            ...selectedCustomers.filter(
                (customer) => !selectedLabelCustomers.some((selected) => selected.id === customer.id)
            ),
        ];

        setSelectedLabelCustomers(combinedCustomers);
        setResetSelection(true);
        setTimeout(() => setResetSelection(false), 0); // Reset back to false after update
    };

    const handleDone = () => {
        let combinedCustomers;

        if (formMode === "EDIT") {
            combinedCustomers = [
                ...selectedLabelCustomers.map((customer) => {
                    if (!previousSelectedCustomers.some((prevCustomer) => prevCustomer.id === customer.id)) {
                        return { id: customer.id, customerName: customer.customerName, action: "ADD" };
                    }
                    return customer;
                }),
                ...previousSelectedCustomers.filter(
                    (customer) => !selectedLabelCustomers.some((selected) => selected.id === customer.id)
                ),
            ];
        } else {
            combinedCustomers = [
                ...selectedLabelCustomers,
                ...previousSelectedCustomers.filter(
                    (customer) => !selectedLabelCustomers.some((selected) => selected.id === customer.id)
                ),
            ];
        }

        dispatch(setSelectedCustomers(combinedCustomers));
        onClose();
    };

    return (
        <>
            <div role="button" onClick={() => onCancel()} className='fixed inset-0 w-full h-full bg-black opacity-40 z-20'></div>
            <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2 p-5 z-30 bg-gray-primary rounded-xl'>
                <h4 className="opacity-60 font-bold pb-5">
                    Customer List
                </h4>
                <HeaderTableCustomer />
                <TableCustomer
                    onSelectionChange={(selectedCustomers) => {
                        setSelectedArrayCustomers(selectedCustomers);
                    }}
                    resetSelection={resetSelection}
                />
                <Pagination
                    totalPage={totalPageCustomer}
                    postsPerPage={postPerPageCustomer}
                    totalPosts={totalDataCustomer}
                    currentPage={currentPageCustomer}
                    onClick={(page) => {
                        Action.getListCustomers(dispatch, {
                            page,
                            search: searchCustomer,
                            size: postPerPageCustomer,
                            status: "1"
                        });
                    }}
                />
                <div className='w-full mt-5 flex justify-end'>
                    <button
                        type='button'
                        onClick={handleSelect}
                        className='button md:w-36 hover:bg-gray-600 border-gray-400 hover:border-gray-600 text-xs tracking-widest font-bold text-gray-primary border-2 bg-gray-400 border-opacity-40 rounded-xl py-2'>
                        Select
                    </button>
                </div>

                <p className='mt-5'>Selected Customer</p>
                <div className='bg-white rounded-xl py-2.5 pl-2.5 pr-16 text-justify relative min-h-[40px] flex flex-wrap gap-2 mt-2'>
                    {selectedLabelCustomers.map(customer => (
                        <label className='px-2.5 py-1 bg-gray-200 rounded-lg gap-2 flex items-center w-fit' key={customer.id}>
                            <span className='capitalize'>{customer.customerName}</span>
                            <span className="font-bold" role='button' onClick={() => {
                                setSelectedLabelCustomers(selectedLabelCustomers.filter(c => c.id !== customer.id));
                            }}>x</span>
                        </label>
                    ))}
                </div>
                <div className='w-full mt-5 flex justify-end'>
                    <button type='button'
                        disabled={selectedLabelCustomers.length === 0}
                        onClick={handleDone}
                        className={`
                        ${selectedLabelCustomers.length === 0 ? "opacity-40 cursor-not-allowed" : "cursor-pointer"}
                        text-center button md:w-36 hover:bg-gray-primary hover:text-green-secondary text-xs tracking-widest font-bold text-gray-primary border-2 border-green-secondary bg-green-secondary border-opacity-40 rounded-xl py-2`}>
                        DONE
                    </button>
                </div>
            </div>
        </>
    );
};

export default ModalTableCustomer;
