import React, { useEffect, useRef } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import reducerSlice, { setOtp } from "./redux/Reducer";
import ButtonGreen from "../../../components/ButtonGreen/ButtonGreen";
import { t } from "react-multi-lang";
import StoreHelper from "../../../services/store-helper";
import Action from "./redux/Action";
import Cookies from "js-cookie";
import UseAuth from "../Auth";
import EncryptDecrypt from "../../../services/encrypt-decrypt";
import NotFound from "../../notfound/NotFound";

const OtpInput = React.forwardRef(
  ({ value, onChange, onKeyDown, errorOtp }, ref) => (
    <input
      ref={ref}
      type="text"
      maxLength="1"
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown} // Handle key press events (Enter, Backspace)
      onFocus={(e) => {
        e.target.select();
      }}
      className={`text-center rounded-lg w-[50px] h-[70px]
        ${
          errorOtp
            ? "border-2 border-red-600 ring-red-600"
            : "border-0 focus:border-gold-primary focus:ring-gold-primary"
        }        
        focus:outline-none focus:ring text-3xl font-bold`}
    />
  )
);

const AuthenticatorCode = ({ onSubmit }) => {
  const store = StoreHelper.generateStoreState(reducerSlice);
  store.getState();

  const MainComponent = () => {
    const dispatch = useDispatch();
    const isAuthenticated = UseAuth.getRole();
    const inputRefs = useRef(new Array(6).fill(null)); // Create ref array for OTP inputs
    const otp = useSelector((state) => state.reducer.otp); // Get OTP from Redux store
    const errorOtp = useSelector((state) => state.reducer.errorOtp);
    const spinner = useSelector((state) => state.reducer.spinner);
    const data = Cookies.get("user");
    const user = EncryptDecrypt.dec(data).enabled2Fa

    const handleSubmit = () => {
      Action.validate2faCode(dispatch, otp);
    };

    const handleInputChange = (e, index) => {
      const value = e.target.value;
      if (/^[0-9]$/.test(value)) {
        const newOtp = [...otp];
        newOtp[index] = value;
        dispatch(setOtp(newOtp));

        // Shift focus to the next input if not the last one
        if (index < inputRefs.current.length - 1) {
          inputRefs.current[index + 1].focus();
        }
      }
    };

    const handleKeyDown = (e, index) => {
      if (e.key === "Backspace") {
        handleBackspace(e, index);
      } else if (e.key === "Enter" && index === otp.length - 1) {
        handleSubmit(); // Submit OTP when "Enter" is pressed on the last input
      }
    };

    const handleBackspace = (e, index) => {
      const newOtp = [...otp];
      if (e.key === "Backspace") {
        if (newOtp[index] === "") {
          if (index > 0) {
            inputRefs.current[index - 1].focus(); // Move focus to the previous input
          }
        } else {
          newOtp[index] = "";
          dispatch(setOtp(newOtp)); // Clear input and update state
        }
      }
    };

    return (
      <>
      
      <div className="section py-10 px-6 flex flex-col">
        <div className="flex flex-col mb-10">
          <h2 className="text-green-secondary font-bold text-2xl mb-4">
            {t("login.authenticatorcode")}
          </h2>
          <p className="text-gray-500 mb-5">
            {t("login.subtitleauthenticatorcode")}
          </p>

          <div className="grid grid-cols-6 text-black gap-y-4 my-6 px-7">
            {otp.map((digit, i) => (
              <OtpInput
                key={i}
                value={digit}
                ref={(el) => (inputRefs.current[i] = el)} // Set ref for each input
                onChange={(e) => handleInputChange(e, i)} // Handle input change
                onKeyDown={(e) => handleKeyDown(e, i)} // Handle Enter and Backspace
                errorOtp={errorOtp}
              />
            ))}
          </div>

          {errorOtp && <p className="text-red-600 mb-4">{errorOtp}</p>}
        </div>

        <div className="flex items-center justify-end ml-0">
          <ButtonGreen title={t("login.verify")} onClick={handleSubmit} disabled={spinner} />
        </div>
      </div>
      
    </>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default AuthenticatorCode;
