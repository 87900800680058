import { useTranslation } from "react-multi-lang";
import { React, useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import ButtonGold from "../../components/ButtonGold/ButtonGold";
import StoreHelper from "../../services/store-helper";
import Admin from "./component/admin/Admin";
import Customer from "./component/customer/Customer";
import Merchant from "./component/merchant/Merchant";
import Product from "./component/product/Product";
import Action from "./redux/Action";
import reducerSlice, { setDisabled } from "./redux/Reducer";
import RequestMerchant from "./component/requestmerchant/RequestMerchant";
import CreateModal from "./component/admin/component/CreateModal";
import UseAuth from "../auth/Auth";
import ButtonRequest from "./component/requestmerchant/component/ButtonRequest";
import Transaction from "./component/transaction/Transaction";
import Promo from "./component/promo/Promo";
import { useNavigate } from "react-router-dom";
import RouteName from "../../services/routename";
import RequestPromo from "./component/requestpromo/RequestPromo";
import GenerateReport from "./component/promo/component/GenerateReport";
import Cookies from "js-cookie";
import EncryptDecrypt from "../../services/encrypt-decrypt";

const Management = ({ getBreadcrums }) => {
  const store = StoreHelper.generateStoreState(reducerSlice);

  const MainComponent = () => {
    store.getState();
    const t = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const page = useSelector((state) => state.reducer.page);
    const showModalAdmin = useSelector((state) => state.reducer.showModalAdmin);
    const disabled = useSelector((state) => state.reducer.disabled);
    const totalReqMerchant = useSelector((state) => state.reducer.totalReqMerchant);
    const totalReqPromo = useSelector((state) => state.reducer.totalReqPromo);
    const nav = [
      { title: t("management.admin"), route: "admin" },
      { title: t("management.merchant"), route: "merchant" },
      { title: t("management.customer"), route: "customer" },
      { title: t("management.product"), route: "product" },
      { title: t("management.transaction"), route: "transaction" },
      { title: t("management.promo"), route: "promo" },
    ];

    const isAdmin = UseAuth.checkFunction("management-admin-page", "function");
    const isMerchant = UseAuth.checkFunction("management-merchant-page", "function");
    const isCustomer = UseAuth.checkFunction("management-customer-page", "function");
    const isProduct = UseAuth.checkFunction("managament-product-page", "function");
    const isTransactionHistory = UseAuth.checkFunction("managament-transaction-history-page", "function");
    const isPromo = UseAuth.checkFunction("management-promo-page", "function");

    const determineInitialPage = () => {
      if (isAdmin) {
        return "admin";
      } else if (isMerchant) {
        return "merchant";
      } else if (isCustomer) {
        return "customer";
      } else if (isProduct) {
        return "product";
      } else if (isTransactionHistory) {
        return "transaction";
      } else if (isPromo) {
        return "promo";
      }
      return "";
    };

    const initialPage = determineInitialPage();

    const getTotal = () => {
      Action.getCountReqMerchant(dispatch);
    };

    const Parent = () => {
      if (
        page === "admin" &&
        UseAuth.checkFunction("management-admin-page", "function") === true
      ) {
        return <Admin />;
      } else if (
        page === "customer" &&
        UseAuth.checkFunction("management-customer-page", "function") === true
      ) {
        return <Customer />;
      } else if (
        page === "merchant" &&
        UseAuth.checkFunction("management-merchant-page", "function") === true
      ) {
        return <Merchant />;
      } else if (
        page === "product" &&
        UseAuth.checkFunction("managament-product-page", "function") === true
      ) {
        return <Product />;
      } else if (
        page === "request" &&
        UseAuth.checkFunction("management-merchant-request-page", "function") === true
      ) {
        return <RequestMerchant getTotal={getTotal} />;
      } else if (
        page === "transaction" &&
        UseAuth.checkFunction("managament-transaction-history-page", "function") === true
      ) {
        return <Transaction />;
      }

      else if (
        page === "promo" &&
        UseAuth.checkFunction("management-promo-page", "function") === true
      ) {
        return <Promo />;
      }
      else if (
        page === "promo-request-approval" &&
        UseAuth.checkFunction("management-request-promo-page", "function") === true
      ) {
        return <RequestPromo />;
      }

      else {
        return "";
      }
    };

    //problem on identifier button
    const ButtonManagement = () => {
      if (
        page === "admin" &&
        UseAuth.checkFunction(
          "createaccount-admin-management-button",
          "button"
        ) === true
      ) {
        return (
          <>
            <ButtonGold
              title={"CREATE ACCOUNT"}
              onClick={() => {
                Action.changeShowModalAdmin(dispatch, { showModal: true });
              }}
            />

            {showModalAdmin && (
              <CreateModal setDisabled={setDisabled} disabled={disabled} />
            )}
          </>
        );
      } else if (
        page === "merchant" &&
        UseAuth.checkFunction(
          "management-merchant-request-page",
          "function"
        ) === true
      ) {
        return (
          <ButtonRequest
            getPage={() => {
              Action.getPage(store, { page: "request" });
            }}
            totalReqMerchant={totalReqMerchant}
          />
        );
      } else if (
        page === "promo"
      ) {
        return <div className="flex items-stretch gap-2">
          {UseAuth.checkFunction("generate-promo-report-button", "button") === true ?
            <GenerateReport />
            : null}

          {UseAuth.checkFunction("create-promo-management-promo-button", "button") === true ?
            <ButtonGold
              title={"ADD PROMO"}
              onClick={() => {
                navigate(RouteName.promo_form);
              }} />
            : null}


          {UseAuth.checkFunction("management-request-promo-page", "function") === true ?
            <>
              <div className="w-[2px] h-full bg-slate-300"></div>
              <ButtonRequest
                getPage={() => {
                  Action.getPage(store, { page: "promo-request-approval" });
                }}
                totalReqMerchant={totalReqPromo.toString()}
              />
            </>
            : null}

        </div>;
      }
    };

    useEffect(() => {
      const cookiePage = Cookies.get("PAGE_MANAGEMENT") ? EncryptDecrypt.dec(Cookies.get("PAGE_MANAGEMENT")) : "admin";
      const page = cookiePage || initialPage;
      Action.getPage(store, { page });
      getBreadcrums(t("management.title"));
      Action.getCountReqMerchant(dispatch);
      Action.getCountReqPromo(dispatch);
      Cookies.remove("PAGE_MANAGEMENT");
    }, []);

    useEffect(() => {
      Action.getCountReqMerchant(dispatch);
      Action.getCountReqPromo(dispatch);
    }, [page]);

    return (
      <>
        <div className="flex flex-row items-center px-2 gap-x-2">
          <i className="mt-1.5">
            <img
              className="h-full w-10"
              src={process.env.REACT_APP_ASSETS_IMAGE + "back-icon.svg"}
              alt="back-icon"
            />
          </i>
          <h2 className="text-green-primary font-bold">Management</h2>
        </div>

        <div className="flex items-center pt-2">
          <nav className="bg-gray-primary rounded-xl p-2">
            <ul className="inline-flex items-center -space-x-px">
              {nav.map((item, index) => {
                // Periksa hasil dari checkFunction untuk menyembunyikan opsi "admin"
                if (
                  item.route === "admin" &&
                  !UseAuth.checkFunction("management-admin-page", "function")
                ) {
                  return null; // Tidak merender opsi "admin" jika tidak memenuhi kondisi
                } else if (
                  item.route === "merchant" &&
                  !UseAuth.checkFunction("management-merchant-page", "function")
                ) {
                  return null;
                } else if (
                  item.route === "customer" &&
                  !UseAuth.checkFunction("management-customer-page", "function")
                ) {
                  return null;
                } else if (
                  item.route === "product" &&
                  !UseAuth.checkFunction("managament-product-page", "function")
                ) {
                  return null;
                } else if (
                  item.route === "transaction" &&
                  !UseAuth.checkFunction("managament-transaction-history-page", "function")
                ) {
                  return null;
                }
                else if (
                  item.route === "promo" &&
                  !UseAuth.checkFunction("management-promo-page", "function")
                ) {
                  return null;
                }
                return (
                  <li
                    key={index}
                    className="cursor-pointer"
                    onClick={() => {
                      Action.getPage(store, { page: item.route });
                    }}
                  >
                    <div
                      className={`${(page === item.route ||
                        (page === "promo-request-approval" && item.route === "promo") ||
                        (page === "request" && item.route === "merchant"))
                        ? "bg-gold-secondary text-white"
                        : ""
                        } block px-6 py-2 rounded-xl drop-shadow-md leading-tight`}>
                      {item.title}
                    </div>
                  </li>
                );
              })}
            </ul>
          </nav>

          <div className="flex-1 flex justify-end">
            <ButtonManagement />
          </div>
        </div>

        <div className="pt-6">
          <Parent />
        </div>
      </>
    );
  };

  return (
    <Provider store={store}>
      <MainComponent />
    </Provider>
  );
};

export default Management;
