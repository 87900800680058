import React, { useEffect, useRef, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import ButtonGreen from "../../../components/ButtonGreen/ButtonGreen";
import { t } from "react-multi-lang";
import StoreHelper from "../../../services/store-helper";
import UseAuth from "../Auth";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const OtpInput = React.forwardRef(
  ({ value, onChange, onKeyDown, errorOtp }, ref) => (
    <input
      ref={ref}
      type="text"
      maxLength="1"
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown} // Use onKeyDown to handle backspace
      onFocus={(e) => {
        e.target.select();
      }}
      className={`text-center rounded-lg w-[50px] h-[70px]
        ${
          errorOtp
            ? "border-2 border-red-600 ring-red-600"
            : "border-0 focus:border-gold-primary focus:ring-gold-primary"
        }        
        focus:outline-none focus:ring text-3xl font-bold`}
    />
  )
);

const AuthenticatorCode = ({ onSubmit }) => {
  const location = useLocation();
  const email = location.state?.email; 
  const dispatch = useDispatch();
  const inputRefs = useRef(new Array(6).fill(null)); 
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [errorOtp, setErrorOtp] = useState("");
  const [spinner, setSpinner] = useState(false);
  
  const handleSubmit = () => {
    UseAuth.sendForgotPassword(otp, setErrorOtp, email, setSpinner, spinner);
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (index < 5) {
        inputRefs.current[index + 1].focus(); // Move focus to next input
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      handleBackspace(e, index);
    } else if (e.key === 'Enter' && index === otp.length - 1) {
      handleSubmit(); // Call handleSubmit when Enter is pressed on the last input
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace') {
      const newOtp = [...otp];
      if (newOtp[index] === '') {
        if (index > 0) {
          inputRefs.current[index - 1].focus(); // Move focus to previous input
        }
      } else {
        newOtp[index] = '';
        setOtp(newOtp);
      }
    }
  };

  return (
    <div className="section py-10 px-6 flex flex-col">
      <div className="flex flex-col mb-10">
        <h2 className="text-green-secondary font-bold text-2xl mb-4">
          {t("login.authenticatorcode")}
        </h2>
        <p className="text-gray-500 mb-5">
          {t("login.subtitleauthenticatorcode")}
        </p>

        <div className="grid grid-cols-6 text-black gap-y-4 my-6 px-7">
          {otp.map((digit, i) => (
            <OtpInput
              key={i}
              value={digit}
              ref={(el) => (inputRefs.current[i] = el)}
              onChange={(e) => handleInputChange(e, i)}
              onKeyDown={(e) => handleKeyDown(e, i)}
              errorOtp={errorOtp}
            />
          ))}
        </div>

        {errorOtp && <p className="text-red-600 mb-4">{errorOtp}</p>}
      </div>

      <div className="flex items-center justify-end ml-0">
        <ButtonGreen title={t("login.verify")} onClick={handleSubmit} disabled={spinner} />
      </div>
    </div>
  );
};

export default AuthenticatorCode;
