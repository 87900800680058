import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import reducerSlice, { setFromDate, setToDate } from './redux/Reducer';
import StoreHelper from '../../../../services/store-helper';
import Detail from './component/Detail';
import Total from './component/Total';
import Action from "./redux/Action";
import HeaderTable from './component/HeaderTable';
import Table from './component/Table';
import Pagination from '../../../../components/Pagination/Pagination';

const Transaction = () => {
    const store = StoreHelper.generateStoreState(reducerSlice);

    const MainComponent = () => {
        const dispatch = useDispatch();
        const showDetail = useSelector((state) => state.reducer.showDetail);
        const totalData = useSelector((state) => state.reducer.totalData);
        const loading = useSelector((state) => state.reducer.loading);
        const currentPage = useSelector((state) => state.reducer.currentPage);
        const search = useSelector((state) => state.reducer.search);
        const statusFilter = useSelector((state) => state.reducer.statusFilter);
        const postPerPage = useSelector((state) => state.reducer.postPerPage);
        const fieldName = useSelector((state) => state.reducer.fieldName);
        const orderBy = useSelector((state) => state.reducer.orderBy);
        const totalPage = useSelector((state) => state.reducer.totalPage);
        const fromDate = useSelector((state) => state.reducer.fromDate);
        const toDate = useSelector((state) => state.reducer.toDate);
        
        useEffect(() => {
            Action.getList(dispatch, {
                search: "",
                currentPage: currentPage,
                postPerPage: 5,
                statusFilter: "",
                fieldName: "",
                orderBy: "",
                toDate: "",
                fromDate: ""
            });
        }, []);

        const ShowDetail = () => {
            if (showDetail) {
                return <Detail />;
            } else {
                return <Total totalPosts={totalData} loading={loading} />;
            }
        };

        return (
            <div className="flex gap-x-3">
                <div className="basis-8/12">
                    <div className="rounded-2xl p-5 bg-gray-primary">
                        <div>
                            <HeaderTable
                                datePickerOnOpen={() => {
                                    dispatch(setFromDate(""));
                                    dispatch(setToDate(""));
                                }}
                                datePickerOnChange={(dateObject) => {
                                    let d0 = new Date(dateObject[0]);
                                    if (dateObject.length > 1) {
                                        let d1 = new Date(dateObject[1]);
                                        dispatch(setFromDate(d0.toISOString()));
                                        dispatch(setToDate(d1.toISOString()));
                                    } else {
                                        dispatch(setFromDate(d0.toISOString()));
                                        dispatch(setToDate(""));
                                    }
                                }}
                                datePickerOnClose={() => {
                                    Action.getList(dispatch, {
                                        currentPage: 0,
                                        search,
                                        statusFilter,
                                        postPerPage,
                                        fieldName,
                                        orderBy,
                                        toDate,
                                        fromDate
                                    });
                                }} />
                        </div>
                        <div className="my-2">
                            <Table />
                        </div>
                        <div>
                            <Pagination
                                totalPage={totalPage}
                                postsPerPage={postPerPage}
                                totalPosts={totalData}
                                currentPage={currentPage}
                                onClick={(page) => {
                                    Action.getList(dispatch, {
                                        currentPage: page,
                                        search,
                                        statusFilter,
                                        postPerPage,
                                        fieldName,
                                        orderBy,
                                        toDate,
                                        fromDate
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className=" basis-4/12 ">
                    <div className="p-5 bg-gray-primary rounded-2xl h-auto">
                        <ShowDetail />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Provider store={store}>
            <MainComponent />
        </Provider>
    );
};

export default Transaction;