import React, { useRef, useState } from "react";
import ButtonDownload from "../ButtonDownload/ButtonDownload";
import { useTranslation } from "react-multi-lang";
import GeneralUtility from "../../utils/general-utility";
import CaptureUtility from "../../utils/capture-utility";

const InvoiceModal = ({ onCancel, title, invoiceDetail }) => {
    const t = useTranslation();
    const [zoom, setZoom] = useState(100);
    const exportRef = useRef();

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 w-full h-full bg-green-quinary bg-opacity-90"></div>
            <div className="flex flex-col min-h-screen px-4">
                <div className="relative px-8 pt-4 text-white">
                    <div className="flex flex-row gap-x-8 items-center">
                        <div className="flex-1">{title}</div>
                        <div className="pt-1">
                            <ButtonDownload
                                title={"Download"}
                                onClick={() => CaptureUtility(exportRef.current, title)}
                            />
                        </div>

                        <div
                            className="text-2xl cursor-pointer font-thin"
                            onClick={onCancel}>
                            x
                        </div>
                    </div>
                </div>
                <div className="relative w-full max-w-4xl mx-auto my-auto shadow-lg">
                    <div
                        style={{ width: zoom + "%", height: zoom + "%" }}
                        ref={exportRef}>
                        <div className="flex bg-green-primary items-end rounded-t-xl p-3">
                            <div className="flex-1 text-white">
                                <h5>{t("invoice.invoice_number")}</h5>
                                <h4>{invoiceDetail.invoiceNumber}</h4>
                            </div>
                            <img
                                className="h-12 mb-8"
                                src={process.env.REACT_APP_ASSETS_IMAGE + "te-logo.svg"}
                                alt="Tanam Emas Logo"
                            />
                        </div>
                        <div className="bg-white rounded-b-xl p-3">
                            <div className="flex">
                                <div className="flex-1 mr-2">
                                    <p className="text-gray-secondary">
                                        {t("invoice.purchase_date")}
                                    </p>
                                    <p className="font-bold">{invoiceDetail.purchaseDate}</p>
                                </div>
                                <div className="flex-1">
                                    <p className="text-gray-secondary">
                                        {t("invoice.shipping_detail")}
                                    </p>
                                    <p className="font-bold">{invoiceDetail.address}</p>
                                </div>
                                <div className="flex-1 ml-2">
                                    <p className="text-gray-secondary">
                                        {t("invoice.payment_method")}
                                    </p>
                                    <p className="font-bold">{invoiceDetail.paymentMethod}</p>
                                </div>
                            </div>
                            <div className="w-full border-b my-2" />
                            <p className="text-gray-secondary text-md">
                                {t("invoice.order_details")}
                            </p>
                            <ul>
                                {invoiceDetail.products.map((item, index) => (
                                    <li className="flex mb-1 items-start" key={index}>
                                        <div className="flex-1 ">
                                            <p className="font-bold">
                                                {item.productName}
                                            </p>
                                            {/* notes */}
                                            <div className="flex text-gray-secondary flex-col">
                                                <p className="ml-2">
                                                    {item.size !== "" ? (
                                                        <span className="inline-block">
                                                            <span className="capitalize">{t("management.size")}</span>
                                                            : {item.size}
                                                        </span>
                                                    ) : ""}
                                                    {item.color !== "" ? (
                                                        <>
                                                            <span className="inline-block mx-2">/</span>
                                                            <span className="inline-block">
                                                                <span className="capitalize">{t("management.color")}</span>
                                                                : {item.color}
                                                            </span>
                                                        </>
                                                    ) : ""}
                                                </p>
                                                <p className="ml-2">
                                                    <span className="capitalize">{t("invoice.notes")}</span>
                                                    : {item.notes}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <p>Rp{item.price}</p>
                                            <p>x{item.quantity}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div className="w-full border-b my-2" />
                            <div className="flex">
                                <p className="flex-1 font-bold">{t("invoice.total_price")}</p>
                                <div className="text-right">
                                    <p>Rp{invoiceDetail.totalPrice}</p>
                                </div>
                            </div>
                            <div className="flex">
                                <p className="flex-1 font-bold">
                                    {t("invoice.total_shipping_cost")}
                                </p>
                                <div className="text-right">
                                    <p>
                                        Rp{invoiceDetail.totalShippingCost}
                                    </p>
                                </div>
                            </div>
                            <div className="flex">
                                <p className="flex-1 font-bold">
                                    {t("invoice.total_insurance_cost")}
                                </p>
                                <div className="text-right">
                                    <p>
                                        Rp{invoiceDetail.totalShippingInsurance}
                                    </p>
                                </div>
                            </div>
                            <div className="flex">
                                <p className="flex-1 font-bold">
                                    {t("invoice.total_application_service_fee")}
                                </p>
                                <div className="text-right">
                                    <p>
                                        Rp{invoiceDetail.applicationServiceFee}
                                    </p>
                                </div>
                            </div>
                            <div className="flex">
                                <p className="flex-1 font-bold">
                                    {t("invoice.total_transaction_service_fee")}
                                </p>
                                <div className="text-right">
                                    <p>
                                        Rp{invoiceDetail.transactionServiceFee}
                                    </p>
                                </div>
                            </div>

                            {parseInt(invoiceDetail.totalPricePromo.replace(/,/g, "")) !== 0 ? (
                                <div className="flex">
                                    <p className="flex-1 font-bold">
                                        {t("balance.productPromo")}
                                    </p>
                                    <div className="text-right">
                                        <p>
                                            -Rp{invoiceDetail.totalPricePromo}
                                        </p>
                                    </div>
                                </div>
                            ) : ""}

                            {parseInt(invoiceDetail.totalAdminPromo.replace(/,/g, "")) !== 0 ? (
                                <div className="flex">
                                    <p className="flex-1 font-bold">
                                        {t("balance.productAdminPromo")}
                                    </p>
                                    <div className="text-right">
                                        <p>
                                            -Rp{invoiceDetail.totalAdminPromo}
                                        </p>
                                    </div>
                                </div>
                            ) : ""}

                            {parseInt(invoiceDetail.totalShippingCostPromo.replace(/,/g, "")) !== 0 ? (
                                <div className="flex">
                                    <p className="flex-1 font-bold">
                                        {t("balance.shippingPromo")}
                                    </p>
                                    <div className="text-right">
                                        <p>
                                            -Rp{invoiceDetail.totalShippingCostPromo}
                                        </p>
                                    </div>
                                </div>
                            ) : ""}

                            <div className="w-full border-b my-2" />
                            <div className="flex rounded-xl bg-gray-primary p-2">
                                <p className="flex-1 font-bold">{t("invoice.total_price")}</p>
                                <div className="text-right">
                                    <p className="font-bold">
                                        Rp{invoiceDetail.total}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceModal;
