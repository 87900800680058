import BalanceRepository from "../../../repositories/BalanceRepository";
import DateUtility from "../../../utils/date-utility";
import PopupUtility from "../../../utils/popup-utility";
import { setData, setList, setPopup, setInquiry, setCanWithdraw, setModalBalance, setInvoiceDetail, setLoading, setHasMoreItems, setCurrentPage } from "./Reducer";
import InvoiceModel from "../../../components/InvoiceModal/model/InvoiceModel";
import GeneralUtility from "../../../utils/general-utility";

const getBalance = async (dispatch) => {
  let response = await BalanceRepository.getBalance();
  if (!response.error) {
    dispatch(setData(response.price));
  }
};

const getHistory = async (dispatch, {
  startDateFilter,
  endDateFilter,
  page,
  size,
  currentList }) => {

  let payload = {
    page,
    size,
    
  };

  if (startDateFilter) {
    const formatDate = DateUtility.dateFormatApi(startDateFilter);
    let dateObj = new Date(formatDate);
    const unixDate = dateObj.getTime();
    payload.startDate = unixDate;
  } else {
    payload.startDate = "";
  }

  if (endDateFilter) {
    const formatDate = DateUtility.dateFormatApi(endDateFilter);
    let dateObj = new Date(formatDate);
    const unixDate = dateObj.getTime();
    payload.endDate = unixDate;
  } else {
    payload.endDate = "";
  }

  let list;
  if (currentList!==0) {
    list = currentList;
  } else {
    list = [];
  }
  dispatch(setLoading(true));
  let response = await BalanceRepository.getBalanceHistory(payload);

  if (!response.error) {
    const res = response.data;
    const newList = [...currentList, ...res];
      dispatch(setList(newList));

    if (res.length > 0) {
      dispatch(setCurrentPage(page += 1));
    } else {
      dispatch(setHasMoreItems(false));
    }
  }

  dispatch(setLoading(false));
};


const inquiry = async (dispatch) => {
  const response = await BalanceRepository.getInquiry();
  if (!response.error) {
    dispatch(setInquiry(response));
  }
};


const postWd = async (dispatch) => {
  let response = await BalanceRepository.postWd();
  dispatch(setPopup(false));
  if (!response.error) {
    getHistory(dispatch, { startDateFilter: "", endDateFilter: "", page: 0, size: 5, currentList: [] });
    getBalance(dispatch);
    PopupUtility.success("Success");
  } else {
    PopupUtility.responseValidate("Failed", response.message);
  }
};

const canWithdraw = async (dispatch) => {
  const response = await BalanceRepository.canWithdraw();
  const data = response.data.data;
  if (!response.error) {
    dispatch(setCanWithdraw(data));
  }
};

const setItemBalance = (dispatch, item) => {
  dispatch(setModalBalance(true));
  const invoice = InvoiceModel({
    invoiceNumber: item.invoiceNumber || "-",
    purchaseDate: DateUtility.formatDate(item.orderDate, "half"),
    address: GeneralUtility.toCapitalize(item.address) || "-",
    paymentMethod: getPaymentMethod(item.paymentMethod) || "-",
    products: formatProductInvoice(item.products) || [],
    totalPrice: GeneralUtility.addCommas(item.price || 0),
    totalShippingCost: GeneralUtility.addCommas(item.shippingCost || 0),
    totalShippingInsurance: GeneralUtility.addCommas(item.shippingInsurance || 0),
    applicationServiceFee: GeneralUtility.addCommas(item.applicationServiceFeeInvoice || 0),
    transactionServiceFee: GeneralUtility.addCommas(item.transactionServiceFee || 0),
    totalPricePromo: GeneralUtility.addCommas(item.totalPricePromo || 0),
    totalShippingCostPromo: GeneralUtility.addCommas(item.totalShippingCostPromo || 0),
    total: GeneralUtility.addCommas(item.totalPrice || 0)
  });
  dispatch(setInvoiceDetail(invoice));
};

const formatProductName = (item) => {
  if (item.category?.includes("Jewellery")) {
    return `${item.name} - ${item.subCategory}`;
  } else {
    const editionPart = item.edition !== "" ? ` - ${item.edition}` : "";
    return `${item.brandName}${editionPart}, ${item.gramName}`;
  }
};

const formatProductInvoice = (products) => {
  return products.map(item => ({
    productName: formatProductName(item),
    price: GeneralUtility.addCommas(item.price),
    quantity: item.quantity,
    notes: item.notes || "-",
    size: item.size || "",
    color: item.color || ""
  }));
};

const getPaymentMethod = (string) => {
  return string.replace(/_/g, ' ');
};

const resetItemBalance = (dispatch) => {
  dispatch(setModalBalance(false));
  dispatch(setInvoiceDetail({}));
};

const Action = {
  getBalance,
  getHistory,
  postWd,
  inquiry,
  canWithdraw,
  setItemBalance,
  resetItemBalance
};

export default Action;
