// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sb a.active {
  color: #efd99d;
  transition: all 0.5s ease-in-out;
  --tw-border-opacity: 1;
  border-right-color: rgb(239 217 157 / var(--tw-border-opacity));
  border-right-width: 4px;
}

.sb a.active .st {
  padding-left: 10px;
}

.sb-i-c {
  background-color: #5d7d73;
  box-shadow: 2px 3px 6px #26473d2e;
  border-radius: 10px;
  right: -1.5em;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gCAAgC;EAChC,sBAAsB;EACtB,+DAA+D;EAC/D,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,iCAAiC;EACjC,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".sb a.active {\n  color: #efd99d;\n  transition: all 0.5s ease-in-out;\n  --tw-border-opacity: 1;\n  border-right-color: rgb(239 217 157 / var(--tw-border-opacity));\n  border-right-width: 4px;\n}\n\n.sb a.active .st {\n  padding-left: 10px;\n}\n\n.sb-i-c {\n  background-color: #5d7d73;\n  box-shadow: 2px 3px 6px #26473d2e;\n  border-radius: 10px;\n  right: -1.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
