import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TotalPromo from './TotalPromo';
import DetailPromo from './DetailPromo';

const ShowDetail = () => {
    const showDetail = useSelector((state) => state.reducer.showDetail);
    const Component = showDetail ? DetailPromo : TotalPromo;

    return <Component/>;
};

export default ShowDetail;
