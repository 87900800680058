const translateEnum = (t) => {
    return Object.freeze({
        "-2": t("management.complained"),
        "-1": t("management.canceled"),
        0: t("management.pending"),
        1: t("management.paid"),
        2: t("management.on_shipment"),
        3: t("management.received"),
        4: t("management.completed"),
        8: t("management.not_withdraw"),
        9: t("management.pending_withdraw"),
        10: t("management.withdraw"),
        11: t("management.READY_TO_SELF_PICKUP"),
    });
};

export { translateEnum };