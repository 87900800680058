import { useTranslation } from 'react-multi-lang';
import reducerSlice, { DEFAULT_VALUE_FORM, setFormMode, setFormValue, setSelectedCustomers, setTemporaryFormValue } from './redux/Reducer';
import { Provider, useDispatch, useSelector } from 'react-redux';
import StoreHelper from '../../../../../../services/store-helper';
import PromoTypeInput from './components/PromoTypeInput';
import PromoChargeInput from './components/PromoChargeInput';
import PromoForInput from './components/PromoForInput';
import MaxAmountToggle from './components/MaxAmountToggle';
import ButtonWhite from "../../../../../../components/ButtonWhite/ButtonWhite";
import ButtonGreen from "../../../../../../components/ButtonGreen/ButtonGreen";
import DateRangePicker from "../../../../../../components/DateRangePicker/DateRangePicker";
import SelectCustomer from './components/SelectCustomer';
import Action from './redux/Action';
import GeneralUtility from '../../../../../../utils/general-utility';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import RouteName from '../../../../../../services/routename';
import SelectMerchant from './components/SelectMerchant';
import UseAuth from '../../../../../auth/Auth';
import Cookies from 'js-cookie';
import EncryptDecrypt from '../../../../../../services/encrypt-decrypt';

const MainComponent = () => {
    const t = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const promoItem = location.state;

    const formValue = useSelector(state => state.reducer.formValue);
    const temporaryFormValue = useSelector(state => state.reducer.temporaryFormValue);
    const formMode = useSelector(state => state.reducer.formMode);
    const errors = useSelector(state => state.reducer.errors);
    const selectedCustomers = useSelector(state => state.reducer.selectedCustomers);
    const disableButton = useSelector(state => state.reducer.disableButton);

    const cookiePromise = () => new Promise((resolve) => {
        const rawCookie = EncryptDecrypt.enc("promo");
        const cookie = Cookies.set("PAGE_MANAGEMENT", rawCookie);
        resolve(cookie);
    });

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        const countError = await Action.validateForm(dispatch, formValue);

        if (disableButton) {
            return;
        }

        if (countError == 0) {
            if (formMode === "EDIT") {
                await Action.handleUpdateForm({
                    dispatch,
                    formValue,
                    selectedCustomers,
                    navigate,
                    cookiePromise,
                    temporaryFormValue
                });
            }
            else {
                await Action.handleSubmitForm({
                    dispatch,
                    formValue,
                    selectedCustomers,
                    navigate,
                    cookiePromise
                });
            }
        }
    };

    useEffect(() => {
        if (promoItem && Object.keys(promoItem).length != 0 && promoItem.id) {
            dispatch(setFormValue(promoItem));
            dispatch(setTemporaryFormValue(promoItem));
            const newCustomers = promoItem.customers.map(item => ({
                id: item.id,
                customerName: item.name
            }));
            dispatch(setSelectedCustomers(newCustomers));
            dispatch(setFormMode("EDIT"));
        } else {
            dispatch(setFormValue(DEFAULT_VALUE_FORM));
            dispatch(setTemporaryFormValue({}));
            dispatch(setSelectedCustomers([]));
            dispatch(setFormMode("ADD"));
        }
    }, []);

    return (
        <>
            <div className="flex flex-row items-center px-2 gap-x-2">
                <i className="mt-1.5">
                    <img
                        className="h-full w-10"
                        src={process.env.REACT_APP_ASSETS_IMAGE + "back-icon.svg"}
                        alt="back-icon"
                    />
                </i>
                <h2 className="text-green-primary font-bold">Management</h2>
            </div>

            <div className='grid grid-cols-7 w-full mt-6 gap-4 items-start'>
                <div className='p-5 bg-gray-primary rounded-2xl col-span-2 flex items-center justify-between'>
                    <p className="font-medium tracking-wider text-md">
                        Add Promo Form
                    </p>
                    <p className='font-bold text-md'>
                        <img className="w-2" src={process.env.REACT_APP_ASSETS_IMAGE + "arrow-right.png"} alt='' />
                    </p>
                </div>
                <form onSubmit={handleSubmitForm} className="p-5 bg-gray-primary rounded-2xl h-auto col-span-5">
                    <h4 className="opacity-60 font-bold pb-5">
                        {t("management.add_promo_form").toUpperCase()}
                    </h4>

                    {/* promo type */}
                    <div className='grid grid-cols-4 mb-8 items-center'>
                        <div className='text-md font-bold text-green-primary'>{t("management.promo_type")}</div>
                        <div className='col-span-3'>
                            <PromoTypeInput />
                        </div>
                    </div>

                    {/* promo name */}
                    <div className="grid grid-cols-4 mb-8 items-center">
                        <div className='text-md font-bold text-green-primary'>{t("management.promo_name")}</div>
                        <div className='col-span-3'>
                            <input
                                value={formValue.promoName}
                                onChange={(e) => {
                                    const name = e.target.name;
                                    const value = e.target.value;
                                    Action.handleFormChange(dispatch, { name, value, formValue });
                                }}
                                placeholder={t("management.input_promo_name")}
                                type='text'
                                name="promoName"
                                className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-slate-400 placeholder:font-medium border border-white ${errors.promoName ? 'border-red-500' : ''}`} />
                            {errors.promoName && <p className='text-red-500 text-xs mt-1'>{errors.promoName}</p>}
                        </div>
                    </div>

                    {/* promo code */}
                    <div className='grid grid-cols-4 mb-8 items-center'>
                        <div className='text-md font-bold text-green-primary'>{t("management.promo_code")}</div>
                        <div className='col-span-3'>
                            <div className='relative'>
                                <input
                                    value={formValue.promoCode}
                                    onChange={(e) => {
                                        const name = e.target.name;
                                        const value = e.target.value;
                                        Action.handleFormChange(dispatch, { name, value, formValue });
                                    }}
                                    name="promoCode"
                                    placeholder={t("management.input_promo_code")}
                                    type='text'
                                    className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-slate-400 placeholder:font-medium border border-white ${errors.promoCode ? 'border-red-500' : ''}`} />
                                <img onClick={() => Action.generateNewCode(dispatch, formValue)}
                                    src={process.env.REACT_APP_ASSETS_IMAGE + "generate-new-code-icon.png"}
                                    className='absolute top-1/2 right-4 -translate-y-1/2 w-5'
                                    alt='' role='button' />
                            </div>
                            {errors.promoCode && <p className='text-red-500 text-xs mt-1'>{errors.promoCode}</p>}
                        </div>
                    </div>

                    {/* promo date range */}
                    <div className='grid grid-cols-4 mb-8 items-center'>
                        <div className='text-md font-bold text-green-primary'>{t("management.promo_date")}</div>
                        <div className='col-span-3 '>
                            <div className='relative'>
                                <DateRangePicker
                                    startDateValue={formValue.date.startDate}
                                    endDateValue={formValue.date.endDate}
                                    minDate={new Date()}
                                    onOpen={() => {
                                        const dateValue = { startDate: "", endDate: "" };
                                        Action.handleFormChange(dispatch, { name: "date", value: dateValue, formValue });
                                    }}
                                    onChange={(dateObject) => {
                                        var d0 = new Date(dateObject[0]);
                                        //gunakan yang d0 bukan d0ISOString
                                        if (dateObject.length > 1) {
                                            var d1 = new Date(dateObject[1]);
                                            const dateValue = { startDate: d0.toISOString(), endDate: d1.toISOString() };
                                            Action.handleFormChange(dispatch, { name: "date", value: dateValue, formValue });
                                        } else {
                                            const dateValue = { startDate: d0.toISOString(), endDate: "" };
                                            Action.handleFormChange(dispatch, { name: "date", value: dateValue, formValue });
                                        }
                                    }}
                                    onClose={() => { }}
                                    format="DD MMM YYYY"
                                    placeholder={t("buyback.date_range_placeholder")}
                                    inputClass={`w-full block sm:text-sm shadow-sm border border-white rounded-xl bg-white placeholder:text-slate-40 cursor-pointer ${errors.promoDate ? 'border-red-500' : ''}`}
                                    containerClassName="w-full" />
                                <img src={process.env.REACT_APP_ASSETS_IMAGE + "calendar-icon.png"}
                                    className='absolute top-1/2 right-4 -translate-y-1/2 w-5'
                                    alt='' role='button' />
                            </div>
                            {errors.promoDate && <p className='text-red-500 text-xs mt-1'>{errors.promoDate}</p>}
                        </div>
                    </div>

                    {/* promo charge */}
                    <div className='grid grid-cols-4 mb-8 items-center'>
                        <div className='text-md font-bold text-green-primary'>{t("management.promo_charge")}</div>
                        <div className='col-span-3'>
                            <PromoChargeInput />
                        </div>
                    </div>

                    {/* select merchant */}
                    {formValue.promoCharge !== "ADMIN" ? (
                        <div className='grid grid-cols-4 mb-8 items-center'>
                            <div className='text-md font-bold text-green-primary'>{t("management.select_merchant")}</div>
                            <div className='col-span-3'>
                                <SelectMerchant />
                                {errors.merchant && <p className='text-red-500 text-xs mt-1'>{errors.merchant}</p>}
                            </div>
                        </div>
                    ) : ""}

                    {/* charge percentage */}
                    {formValue.promoCharge === "ADMIN_AND_MERCHANT" ? (
                        <div className='grid grid-cols-4 mb-8 items-center'>
                            <div className='text-md font-bold text-green-primary'>{t("management.charge_percentage")}</div>
                            <div className='col-span-3'>
                                <div className='relative w-[200px]'>
                                    <input
                                        value={formValue.chargePercentage}
                                        onChange={(e) => {
                                            const name = e.target.name;
                                            const value = e.target.value.replace(/[^0-9]/g, "");
                                            Action.handleFormChange(dispatch, { name, value, formValue });
                                        }}
                                        placeholder={t("management.input_charge_percentage")}
                                        type='text'
                                        name="chargePercentage"
                                        className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-slate-400 placeholder:font-medium border border-white pr-10 ${errors.chargePercentage ? 'border-red-500' : ''}`} />
                                    <span className='absolute top-1/2 right-4 -translate-y-1/2 w-5 text-lg font-bold' role='button' >%</span>
                                </div>
                                {errors.chargePercentage && <p className='text-red-500 text-xs mt-1'>{errors.chargePercentage}</p>}
                            </div>
                        </div>
                    ) : ""}

                    {/* promo for */}
                    <div className='grid grid-cols-4 mb-8 items-center'>
                        <div className='text-md font-bold text-green-primary'>{t("management.promo_for")}</div>
                        <div className='col-span-3'>
                            <PromoForInput />
                        </div>
                    </div>

                    {/* select customer */}
                    {formValue.promoTarget === "SPECIFIC_USER" ? (
                        <div className='grid grid-cols-4 mb-8 items-start'>
                            <div className='text-md font-bold text-green-primary'>{t("management.select_customer")}</div>
                            <div className='col-span-3'>
                                <SelectCustomer />
                            </div>
                        </div>
                    ) : ""}

                    {/* amount */}
                    {formValue.promoType === "PERCENTAGE" ? (
                        <div className='grid grid-cols-4 mb-8 items-start'>
                            <div className='text-md font-bold text-green-primary'>{t("management.amount")}</div>
                            <div className='col-span-3'>
                                <div className='relative w-[200px]'>
                                    <input
                                        value={formValue.amount}
                                        onChange={(e) => {
                                            const name = e.target.name;
                                            const value = e.target.value.replace(/[^0-9]/g, "");
                                            Action.handleFormChange(dispatch, { name, value, formValue });
                                        }}
                                        placeholder={t("management.input_promo_amount")}
                                        type='text'
                                        name="amount"
                                        className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-slate-400 placeholder:font-medium border border-white pr-10 ${errors.amount ? 'border-red-500' : ''}`} />
                                    <span className='absolute top-1/2 right-4 -translate-y-1/2 w-5 text-lg font-bold' role='button' >%</span>
                                </div>
                                {errors.amount && <p className='text-red-500 text-xs mt-1'>{errors.amount}</p>}
                                <MaxAmountToggle />
                            </div>
                        </div>
                    ) : ""}

                    {/* discount amount */}
                    {formValue.promoType === "FIXED_AMOUNT" ? (
                        <div className='grid grid-cols-4 mb-8 items-center'>
                            <div className='text-md font-bold text-green-primary'>{t("management.discount_amount")}</div>
                            <div className='col-span-3'>
                                <label className="relative block">
                                    <span className="absolute inset-y-0 left-2.5 flex items-center">
                                        <p className="font-bold text-green-secondary">Rp</p>
                                    </span>
                                    <input
                                        type="text"
                                        name="discountAmount"
                                        onChange={(e) => {
                                            const name = e.target.name;
                                            const value = e.target.value.replace(/[^0-9]/g, "");
                                            Action.handleFormChange(dispatch, { name, value, formValue });
                                        }}
                                        placeholder={t("management.input_discount_amount")}
                                        className={`pl-10 rounded-xl w-full text-right text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errors.discountAmount
                                            ? "border-red-500 border-1"
                                            : "border-0"
                                            }`}
                                        value={GeneralUtility.addCommas(formValue.discountAmount)}
                                        maxLength={100}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                    />
                                </label>
                                {errors.discountAmount && <p className='text-red-500 text-xs mt-1'>{errors.discountAmount}</p>}
                            </div>
                        </div>
                    ) : ""}

                    {/* max discount amount */}
                    {formValue.includeMaxAmount === true && formValue.promoType === "PERCENTAGE" ? (
                        <div className='grid grid-cols-4 mb-8 items-center'>
                            <div className='text-md font-bold text-green-primary'>{t("management.max_discount_amount")}</div>
                            <div className='col-span-3'>
                                <label className="relative block">
                                    <span className="absolute inset-y-0 left-2.5 flex items-center">
                                        <p className="font-bold text-green-secondary">Rp</p>
                                    </span>
                                    <input
                                        type="text"
                                        name="maxDiscountAmount"
                                        onChange={(e) => {
                                            const name = e.target.name;
                                            const value = e.target.value.replace(/[^0-9]/g, "");
                                            Action.handleFormChange(dispatch, { name, value, formValue });
                                        }}
                                        placeholder={t("management.input_max_discount_amount")}
                                        className={`pl-10 rounded-xl w-full text-right text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errors.maxDiscountAmount
                                            ? "border-red-500 border-1"
                                            : "border-0"
                                            }`}
                                        value={GeneralUtility.addCommas(formValue.maxDiscountAmount)}
                                        maxLength={100}
                                        onFocus={(e) => {
                                            e.target.select();
                                        }}
                                    />
                                </label>
                                {errors.maxDiscountAmount && <p className='text-red-500 text-xs mt-1'>{errors.maxDiscountAmount}</p>}
                            </div>
                        </div>
                    ) : ""}

                    {/* minimun transaction */}
                    <div className='grid grid-cols-4 mb-8 items-center'>
                        <div className='text-md font-bold text-green-primary'>{t("management.minimum_transaction")}</div>
                        <div className='col-span-3'>
                            <label className="relative block">
                                <span className="absolute inset-y-0 left-2.5 flex items-center">
                                    <p className="font-bold text-green-secondary">Rp</p>
                                </span>
                                <input
                                    type="text"
                                    name="minimumTransaction"
                                    onChange={(e) => {
                                        const name = e.target.name;
                                        const value = e.target.value.replace(/[^0-9]/g, "");
                                        Action.handleFormChange(dispatch, { name, value, formValue });
                                    }}
                                    placeholder={t("management.input_minimum_transaction")}
                                    className={`pl-10 rounded-xl w-full text-right text-sm font-bold bg-white placeholder:text-green-secondary placeholder:font-medium ${errors.minimumTransaction
                                        ? "border-red-500 border-1"
                                        : "border-0"
                                        }`}
                                    value={GeneralUtility.addCommas(formValue.minimumTransaction)}
                                    maxLength={100}
                                    onFocus={(e) => {
                                        e.target.select();
                                    }}
                                />
                            </label>
                            {errors.minimumTransaction && <p className='text-red-500 text-xs mt-1'>{errors.minimumTransaction}</p>}
                        </div>
                    </div>

                    {/* discount applies to */}
                    <div className='grid grid-cols-4 mb-8 items-center'>
                        <div className='text-md font-bold text-green-primary'>{t("management.discount_applies_to")}</div>
                        <div className='col-span-3'>
                            <select
                                className={`text-sm border ${errors.discountAppliesTo ? "border-red-500" : "border-gray-primary"} rounded-xl w-full bg-white font-medium `}
                                name="discountAppliesTo"
                                value={formValue.discountAppliesTo}
                                onChange={(e) => {
                                    const name = e.target.name;
                                    const value = e.target.value;
                                    Action.handleFormChange(dispatch, { name, value, formValue });
                                }}>
                                <option value="" disabled>
                                    {t("management.select_target")}
                                </option>
                                <option value="ITEM_PRICE">Item Price</option>
                                <option value="ADMIN_FEE">Admin Fee</option>
                                <option value="SHIPPING_FEE">Shipping Fee</option>
                            </select>
                            {errors.discountAppliesTo && <p className='text-red-500 text-xs mt-1'>{errors.discountAppliesTo}</p>}
                        </div>
                    </div>

                    {/* quota */}
                    <div className='grid grid-cols-4 mb-8 items-center'>
                        <div className='text-md font-bold text-green-primary'>{t("management.promo_quota")}</div>
                        <div className='col-span-3'>
                            <input
                                value={formValue.quota}
                                onChange={(e) => {
                                    const name = e.target.name;
                                    const value = e.target.value.replace(/[^0-9]/g, "").toString();
                                    Action.handleFormChange(dispatch, { name, value, formValue });
                                }}
                                placeholder={t("management.input_promo_quota")}
                                type='text'
                                name="quota"
                                className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-slate-400 placeholder:font-medium border border-white ${errors.quota ? 'border-red-500' : ''}`} />
                            {errors.quota && <p className='text-red-500 text-xs mt-1'>{errors.quota}</p>}
                        </div>
                    </div>

                    {/* description */}
                    <div className='grid grid-cols-4 mb-8 items-center'>
                        <div className='text-md font-bold text-green-primary'>{t("management.description")}</div>
                        <div className='col-span-3'>
                            <textarea
                                value={formValue.description}
                                onChange={(e) => {
                                    const name = e.target.name;
                                    const value = e.target.value;
                                    Action.handleFormChange(dispatch, { name, value, formValue });
                                }}
                                name="description"
                                placeholder={t("management.insert_description")}
                                type='text'
                                className={`rounded-xl w-full text-sm font-bold bg-white placeholder:text-slate-400 placeholder:font-medium border border-white pr-10 ${errors.description ? 'border-red-500' : ''}`} >
                            </textarea>
                            {errors.description && <p className='text-red-500 text-xs mt-1'>{errors.description}</p>}
                        </div>
                    </div>

                    <div className="items-stretch gap-x-2 py-4 flex justify-end">
                        <ButtonWhite
                            title={t("general.cancel").toUpperCase()}
                            onClick={async (e) => {
                                await cookiePromise();
                                navigate(RouteName.management);
                            }}
                        />
                        {UseAuth.checkFunction("update-promo-management-promo-button", "button") === true ||
                            UseAuth.checkFunction("create-promo-management-promo-button", "button") === true ?
                            <ButtonGreen
                                title={formMode === "EDIT" ? "update" : t("management.add_promo").toUpperCase()}
                                onClick={(e) => {

                                }}
                                disabled={disableButton}
                                loading={disableButton}
                            />
                            : null}
                    </div>
                </form >
            </div>
        </>
    );
};

const PromoForm = () => {
    const store = StoreHelper.generateStoreState(reducerSlice);

    return (
        <Provider store={store}>
            <MainComponent />
        </Provider>
    );
};

export default PromoForm;