import ManagementPromoRepository from "../../../../../repositories/ManagementPromoRepository";
import PopupUtility from "../../../../../utils/popup-utility";
import { setCurrentPage, setDetailPromo, setDisabledButton, setFieldName, setList, setLoading, setOrderBy, setPostPerPage, setSearch, setShowDetail, setTotalData, setTotalPage, setTypeDisableButton } from "./Reducer";

const getList = async (
    dispatch,
    { currentPage, search, postPerPage, fieldName, orderBy }
) => {

    dispatch(setLoading(true));
    dispatch(setCurrentPage(currentPage));
    dispatch(setSearch(search));
    dispatch(setPostPerPage(postPerPage));
    dispatch(setFieldName(fieldName));
    dispatch(setOrderBy(orderBy));

    const { error, data, total_data, total_pages } = await ManagementPromoRepository.getListRequest({
        currentPage,
        search,
        postPerPage,
        fieldName,
        orderBy
    });

    if (!error) {
        dispatch(setList(data));
        dispatch(setLoading(false));
        dispatch(setTotalData(total_data));
        dispatch(setTotalPage(total_pages));
    }
};

const changeShowDetail = (dispatch, boolean, item) => {
    if (item) {
        dispatch(setDetailPromo(item));
    } else {
        dispatch(setDetailPromo({}));
    }
    dispatch(setShowDetail(boolean));
};

const confirmPromo = async ({ type, promoId, dispatch }) => {
    const TYPE_ENUM = {
        "accepted": "approve",
        "declined": "decline"
    };

    dispatch(setTypeDisableButton(type));
    dispatch(setDisabledButton(true));
    const { error, message } = await ManagementPromoRepository.confirmPromo({
        status: TYPE_ENUM[type],
    }, promoId);

    if (!error) {
        let message = `Successfully ${type.charAt(0).toUpperCase() + type.slice(1)} the request`;
        PopupUtility.success(message);
        resetAfterConfirm(dispatch);
        return getList(dispatch, {
            search: "",
            currentPage: 0,
            postPerPage: 5,
            fieldName: "",
            orderBy: ""
        });
    }

    PopupUtility.responseValidate("failed", message);
    resetAfterConfirm(dispatch);
};

const resetAfterConfirm = (dispatch) => {
    dispatch(setShowDetail(false));
    dispatch(setTypeDisableButton(""));
    dispatch(setDisabledButton(false));
};

const Action = {
    getList,
    changeShowDetail,
    confirmPromo
};

export default Action;