import Endpoint from "../services/endpoint";
import CallApi from "../services/request-helper";
import DateUtility from "../utils/date-utility";
import GeneralUtility from "../utils/general-utility";
const BASE_API = process.env.REACT_APP_BASE_API;

const STATUS_ENUM = {
    "ACTIVE": "Active",
    "NON_ACTIVE": "Inactive",
    "REQUEST_APPROVAL": "Request Approval",
    "PENDING": "Pending"
};

const TARGET_ENUM = {
    "EVERYONE": "Everyone",
    "SPECIFIC_USER": "Specific User",
    "NEW_ACCOUNT": "New Account"
};

const APPLIES_TO_ENUM = {
    "ITEM_PRICE": "Item Price",
    "ADMIN_FEE": "Admin Fee",
    "SHIPPING_FEE": "Shipping Fee",
};

const TYPE_ENUM = {
    "FIXED_AMOUNT": "Fixed Amount",
    "PERCENTAGE": "Percentage"
};

const CHARGE_ENUM = {
    "ADMIN": "Admin",
    "MERCHANT": "Merchant",
    "ADMIN_AND_MERCHANT": "Admin & Merchant",
};

const getList = async (payload) => {
    const url = BASE_API + Endpoint.promo;
    const params = {
        page: payload.currentPage || 0,
        size: payload.postPerPage || 5,
        sortBy: payload.fieldName || null,
        sortByDirection: payload.orderBy || null,
        search: payload.search || null,
        status: payload.statusFilter || null,
    };
    const response = await CallApi({ url, method: "GET", params });
    const response1 = typeof response != "undefined" ? response.data : null;
    const response2 = response1 != "undefined" && response1 != null ? response1.data : null;
    const list = typeof response2 != "undefined" && response2 != null ? response2.content : [];

    const newList = list.map(item => ({
        ...item,
        promoName: item.name,
        promoCode: item.code,
        promoCharge: CHARGE_ENUM[item.charge],
        status: STATUS_ENUM[item.status],
        promoTarget: TARGET_ENUM[item.target],
        promoDiscountAppliesTo: APPLIES_TO_ENUM[item.discountAppliesTo],
        promoType: TYPE_ENUM[item.type],
        totalSpecificUser: getTotalSpecificUser(item.customers),
        maxDiscountAmount: GeneralUtility.addCommas(item.maxDiscountAmount || 0),
        minimumTransaction: GeneralUtility.addCommas(item.minimumTransaction || 0),
        discountAmount: GeneralUtility.addCommas(item.discountAmount || 0),
        date: DateUtility.formatDate(item.startDate, "half") + " - " + DateUtility.formatDate(item.endDate, "half"),
    }));

    let data = {};
    data.error = response.error;
    data.message = response.message;
    data.page = response2 ? response2.number : 0;
    data.per_page = response2 ? response2.size : 0;
    data.total_data = response2 ? response2.totalElements : 0;
    data.total_pages = response2 ? response2.totalPages : 0;
    data.data = newList;
    return data;
};

const getListRequest = async (payload) => {
    const url = BASE_API + Endpoint.promoRequest;
    const params = {
        page: payload.currentPage || 0,
        size: payload.postPerPage || 5,
        sortBy: payload.fieldName || null,
        sortByDirection: payload.orderBy || null,
        search: payload.search || null
    };
    const response = await CallApi({ url, method: "GET", params });
    const response1 = typeof response != "undefined" ? response.data : null;
    const response2 = response1 != "undefined" && response1 != null ? response1.data : null;
    const list = typeof response2 != "undefined" && response2 != null ? response2.content : [];

    const newList = list.map(item => ({
        ...item,
        promoName: item.name,
        promoCode: item.code,
        promoCharge: CHARGE_ENUM[item.charge],
        status: STATUS_ENUM[item.status],
        promoTarget: TARGET_ENUM[item.target],
        promoDiscountAppliesTo: APPLIES_TO_ENUM[item.discountAppliesTo],
        promoType: TYPE_ENUM[item.type],
        totalSpecificUser: getTotalSpecificUser(item.customers),
        maxDiscountAmount: GeneralUtility.addCommas(item.maxDiscountAmount || 0),
        minimumTransaction: GeneralUtility.addCommas(item.minimumTransaction || 0),
        discountAmount: GeneralUtility.addCommas(item.discountAmount || 0),
        date: DateUtility.formatDate(item.startDate, "half") + " - " + DateUtility.formatDate(item.endDate, "half"),
    }));

    let data = {};
    data.error = response.error;
    data.message = response.message;
    data.page = response2 ? response2.number : 0;
    data.per_page = response2 ? response2.size : 0;
    data.total_data = response2 ? response2.totalElements : 0;
    data.total_pages = response2 ? response2.totalPages : 0;
    data.data = newList;
    return data;
};

const getCountReqPromo = async () => {
    const url = BASE_API + Endpoint.promoCountTotal;

    const response = await CallApi({ url, method: "GET" });
    const response1 = typeof response != "undefined" ? response.data : null;
    const response2 = response1 != "undefined" && response1 != null ? response1.data : null;

    let data = {};
    data.error = response.error;
    data.message = response.message;
    data.data = response2.total;
    return data;
};

const getTotalSpecificUser = (customers) => {
    if (!customers) {
        return 0;
    }
    return customers.length;
};

const confirmPromo = async ({ status }, promoId) => {
    const url = BASE_API + Endpoint.promoRequest + "/" + status + "/" + promoId;
    const response = await CallApi({ url, method: "POST" });
    const response1 = typeof response != "undefined" ? response.data : null;

    let data = {};
    data.error = response.error;
    data.message = response.message;
    data.data = response1;
    return data;
};

const create = async (payload) => {
    const url = BASE_API + Endpoint.promo;
    const response = await CallApi({ url, method: "POST", data: payload });

    let data = {};
    data.error = response.error;
    data.message = response.message;
    data.trace_id = response.trace_id;
    data.data = null;
    return data;
};

const update = async (payload, promoId) => {
    const url = BASE_API + Endpoint.promo + "/" + promoId;
    const response = await CallApi({ url, method: "PATCH", data: payload });

    let data = {};
    data.error = response.error;
    data.message = response.message;
    data.trace_id = response.trace_id;
    data.data = null;
    return data;
};

const deletePromo = async (promoId, payload) => {
    const url = BASE_API + Endpoint.promo + "/" + promoId;
    const response = await CallApi({ url, method: "DELETE", data: payload });

    let data = {};
    data.error = response.error;
    data.message = response.message;
    data.trace_id = response.trace_id;
    data.data = null;
    return data;
};

const generateReport = async (params) => {
    const url = BASE_API + Endpoint.promoReport;
    const response = await CallApi({ url, method: "GET", params });
    return response;
};

const ManagementPromoRepository = {
    getList,
    getListRequest,
    getCountReqPromo,
    confirmPromo,
    create,
    update,
    deletePromo,
    generateReport
};

export default ManagementPromoRepository;