import React, { useState } from 'react';
import ButtonWhite from '../../../../../components/ButtonWhite/ButtonWhite';
import GenerateReportModal from './GenerateReportModal';

const GenerateReport = () => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <ButtonWhite
                title={"GENERATE REPORT"}
                onClick={(e) => {
                    setOpenModal(true);
                }} />

            {openModal && (
                <GenerateReportModal onCancel={() => setOpenModal(false)} />
            )}
        </>
    );
};

export default GenerateReport;