import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Action from '../redux/Action';

const PromoTypeInput = () => {
    const dispatch = useDispatch();
    const formValue = useSelector(state => state.reducer.formValue);

    return (
        <div className="flex flex-row items-center gap-x-5">
            <div className="flex items-center">
                <input
                    checked={formValue.promoType === "FIXED_AMOUNT"}
                    onChange={(e) => {
                        const name = e.target.name;
                        const value = e.target.value;
                        Action.handleFormChange(dispatch, { name, value, formValue });
                    }}
                    id="FIXED_AMOUNT"
                    type="radio"
                    value="FIXED_AMOUNT"
                    name="promoType"
                    className="w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary" />
                <label
                    htmlFor="FIXED_AMOUNT"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Fixed Amount
                </label>
            </div>
            <div className="flex items-center">
                <input
                    checked={formValue.promoType === "PERCENTAGE"}
                    onChange={(e) => {
                        const name = e.target.name;
                        const value = e.target.value;
                        Action.handleFormChange(dispatch, { name, value, formValue });
                    }}
                    id="PERCENTAGE"
                    type="radio"
                    value="PERCENTAGE"
                    name="promoType"
                    className="w-4 h-4 text-gold-secondary bg-gray-100 border-gray-300 focus:ring-gray-primary" />
                <label
                    htmlFor="PERCENTAGE"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Percentage
                </label>
            </div>
        </div>
    );
};

export default PromoTypeInput;