const Enum = (t, value) => {
    return Object.freeze({
        "-1": t("helpcenter.declined"),
        0: t("helpcenter.opened"),
        1: t("helpcenter.active"),
        2: t("helpcenter.req_resolve"),
        3: t("helpcenter.resolved"),
        4: t("helpcenter.completed"),
        5: t("helpcenter.req_suspend"),
    })[value];
};

export default Enum;