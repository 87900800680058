function CustomerModel({ id, name, joinDate, email, status, phoneNumber }) {
  let obj = {};
  obj.id = id;
  obj.name = name;
  obj.joinDate = joinDate;
  obj.email = email;
  obj.status = status;
  obj.phoneNumber = phoneNumber;
  return obj;
}

export default CustomerModel;
