import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import Action from '../redux/Action';
import { setFormValue } from '../redux/Reducer';

const SelectMerchant = () => {
    const dispatch = useDispatch();

    const errors = useSelector(state => state.reducer.errors);
    const formValue = useSelector(state => state.reducer.formValue);

    const loadOptions = async (inputValue) => {
        try {
            const response = await Action.getMerchantList({ search: inputValue.toLowerCase() });
            return response;
        } catch (error) {
            console.error("Error loading options:", error);
            return [];
        }
    };

    return (
        <AsyncSelect
            styles={{
                control: (baseStyles) => ({
                    ...baseStyles,
                    borderRadius: "12px",
                    borderColor: errors.merchant ? 'red' : 'transparent',
                }),
            }}
            cacheOptions
            defaultOptions
            name="merchant"
            className={`text-sm bg-white font-medium rounded-xl w-full ${errors.merchant ? 'border-red-500' : ''}`}
            loadOptions={loadOptions}
            defaultValue={formValue.merchant}
            onChange={(item) => {
                dispatch(setFormValue(
                    {
                        ...formValue,
                        merchant: item
                    }
                ));
            }}
        />
    );
};

export default SelectMerchant;
