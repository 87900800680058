import { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import ShowDetail from './component/ShowDetail';
import Table from './component/Table';
import HeaderTable from './component/HeaderTable';
import StoreHelper from '../../../../services/store-helper';
import reducerSlice from "./redux/Reducer";
import Pagination from '../../../../components/Pagination/Pagination';
import Action from './redux/Action';

const RequestPromo = () => {
    const store = StoreHelper.generateStoreState(reducerSlice);

    const MainComponent = () => {
        const dispatch = useDispatch();

        const totalPage = useSelector((state) => state.reducer.totalPage);
        const totalData = useSelector((state) => state.reducer.totalData);
        const currentPage = useSelector((state) => state.reducer.currentPage);
        const postPerPage = useSelector((state) => state.reducer.postPerPage);
        const search = useSelector((state) => state.reducer.search);
        const fieldName = useSelector((state) => state.reducer.fieldName);
        const orderBy = useSelector((state) => state.reducer.orderBy);

        useEffect(() => {
            Action.getList(dispatch, {
                search: "",
                currentPage: 0,
                postPerPage: 5,
                fieldName: "",
                orderBy: ""
            });
        }, []);

        return (
            <div className="flex gap-x-3">
                <div className="basis-8/12">
                    <div className="rounded-2xl p-5 bg-gray-primary">
                        <div>
                            <HeaderTable
                            />
                        </div>
                        <div className="my-2">
                            <Table />
                        </div>
                        <div>
                            <Pagination
                                totalPage={totalPage}
                                postsPerPage={postPerPage}
                                totalPosts={totalData}
                                currentPage={currentPage}
                                onClick={(page) => {
                                    Action.getList(dispatch, {
                                        currentPage: page,
                                        search,
                                        postPerPage,
                                        fieldName,
                                        orderBy,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className=" basis-4/12 ">
                    <div className="p-5 bg-gray-primary rounded-2xl h-auto">
                        <ShowDetail />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Provider store={store}>
            <MainComponent />
        </Provider>
    );
};

export default RequestPromo;